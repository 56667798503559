import React, { useState, useEffect, useCallback } from "react";
import {
  getQuestionsForReview,
  submitReview,
  getReviewedQuestionsCount,
} from "../../../../api/auth";
import { useSelector } from "react-redux";
import {
  Button,
  TextField,
  Typography,
  Card,
  CardContent,
  Box,
  CircularProgress,
  Divider,
} from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const AdminReviewCenter = () => {
  const { userId } = useSelector((state) => state.auth); // Retrieve userId from Redux
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [feedback, setFeedback] = useState({});
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [reviewedCount, setReviewedCount] = useState(0);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogAction, setDialogAction] = useState(null);
  const [currentQuestionId, setCurrentQuestionId] = useState(null);

  const fetchQuestions = useCallback(
    async (currentPage) => {
      setLoading(true);
      try {
        const limit = 20;
        const offset = (currentPage - 1) * limit;
        const data = await getQuestionsForReview(userId, limit, offset);

        const refreshedQuestions = data.questions.map((q) => {
          const parsedChoices = Array.isArray(q.choices)
            ? q.choices
            : JSON.parse(q.choices);

          return {
            ...q,
            choices: parsedChoices.map((choice) => ({
              ...choice,
              is_correct: choice.is_correct === true,
            })),
          };
        });

        setQuestions((prevQuestions) => [
          ...prevQuestions,
          ...refreshedQuestions,
        ]);
        setHasMore(data.questions.length > 0);
      } catch (error) {
        console.error("Error fetching questions:", error);
        setError("Failed to fetch questions. Please try again.");
      } finally {
        setLoading(false);
      }
    },
    [userId]
  );

  useEffect(() => {
    fetchQuestions(page);
  }, [page, fetchQuestions]);

  const handleLoadMore = () => {
    if (hasMore && !loading) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handleConfirm = async (questionId) => {
    try {
      const response = await submitReview({
        question_id: questionId,
        reviewer_id: userId,
        is_confirmed: true,
        feedback_text: null,
      });

      const { confirm_count } = response.updatedQuestion;

      if (confirm_count >= 1) {
        setQuestions((prevQuestions) =>
          prevQuestions.filter((q) => q.question_id !== questionId)
        );
        console.log("Question confirmed. Fetching updated reviewed count...");

        const updatedCount = await getReviewedQuestionsCount(userId);
        console.log("Updated reviewed count:", updatedCount);

        setReviewedCount(updatedCount);
      }
    } catch (error) {
      console.error("Error confirming question:", error.message);
      setError("Failed to confirm the question. Please try again.");
    }
  };
  useEffect(() => {
    const fetchReviewedCount = async () => {
      try {
        console.log("Fetching reviewed questions count...");
        const count = await getReviewedQuestionsCount(userId);
        console.log("Fetched reviewed count:", count);
        setReviewedCount(count);
      } catch (error) {
        console.error(
          "Error fetching reviewed questions count:",
          error.message
        );
        setError("Failed to fetch reviewed questions count. Please try again.");
      }
    };

    fetchReviewedCount();
  }, [userId]);
  const handleSubmitFeedback = async (questionId) => {
    try {
      const feedbackText = feedback[questionId] || "";
      await submitReview({
        question_id: questionId,
        reviewer_id: userId,
        is_confirmed: false,
        feedback_text: feedbackText,
      });

      setQuestions((prevQuestions) =>
        prevQuestions.filter((q) => q.question_id !== questionId)
      );

      alert("Feedback submitted successfully.");
    } catch (error) {
      console.error("Error submitting feedback:", error);
      setError("Failed to submit feedback. Please try again.");
    }
  };

  const handleFeedbackChange = (questionId, value) => {
    setFeedback((prevFeedback) => ({
      ...prevFeedback,
      [questionId]: value,
    }));
  };

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box sx={{ padding: "20px" }}>
      <Typography variant="h4" gutterBottom>
        Admin Review Center
      </Typography>
      <Typography variant="h6" gutterBottom>
        Total Questions Reviewed:{" "}
        {error ? "Error fetching count" : reviewedCount}
      </Typography>
      {questions.length === 0 && !loading ? (
        <Typography>No questions available for review.</Typography>
      ) : (
        <>
          {questions.map((question) => (
            <Card
              key={question.question_id}
              sx={{
                marginBottom: "20px",
                border: "1px solid #ddd",
                borderRadius: "2px",

                padding: "16px",
              }}
            >
              <CardContent>
                {/* Check if the question is resubmitted */}
                {question.is_resubmitted && (
                  <Typography
                    color="primary"
                    sx={{ marginBottom: "10px", fontWeight: "bold" }}
                  >
                    Resubmitted for Review
                  </Typography>
                )}

                <Typography
                  variant="subtitle2"
                  color="textSecondary"
                  sx={{ marginBottom: "10px" }}
                >
                  Type:{" "}
                  {question.type === 1
                    ? "MCQ"
                    : question.type === 2
                    ? "VSAQ"
                    : "Unknown"}
                </Typography>

                {/* Question Text */}
                <Typography variant="h6">
                  Question: {question.question_text}
                </Typography>

                {/* Lecture Reference */}
                <Typography variant="subtitle1" color="textSecondary">
                  Lecture: {question.lecture_reference}
                </Typography>

                {/* Display Category and Subcategory */}
                <Typography
                  variant="subtitle2"
                  color="textSecondary"
                  sx={{ marginBottom: "10px" }}
                >
                  Category: {question.category_name || "N/A"} <br />
                  Subcategory: {question.subcategory_name || "N/A"}
                </Typography>

                {/* Display Choices */}
                <Box sx={{ marginLeft: "20px", marginBottom: "10px" }}>
                  {question.choices.map((choice, index) => (
                    <Box
                      key={choice.option_id}
                      sx={{ marginLeft: "20px", marginBottom: "10px" }}
                    >
                      <Typography>
                        Choice {index + 1}: {choice.option_text}{" "}
                        {choice.is_correct && (
                          <span style={{ color: "green" }}>(Correct)</span>
                        )}
                      </Typography>
                      {choice.explanation_text && (
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{ marginLeft: "20px" }}
                        >
                          Explanation: {choice.explanation_text}
                        </Typography>
                      )}
                    </Box>
                  ))}
                </Box>

                {/* Uploaded By and Assigned Reviewer */}
                <Typography
                  sx={{ marginBottom: "5px", fontWeight: "bold" }}
                  variant="subtitle2"
                  color="textSecondary"
                >
                  Uploaded by: {question.uploaded_by}
                </Typography>
                <Typography
                  sx={{ marginBottom: "5px", fontWeight: "bold" }}
                  variant="subtitle2"
                  color="textSecondary"
                >
                  Assigned Reviewer: {question.assigned_reviewer || "N/A"}
                </Typography>

                <Typography variant="h6">Feedback History</Typography>
                {Array.isArray(question.feedback_history) &&
                question.feedback_history.length > 0 ? (
                  question.feedback_history.map((feedback, index) => (
                    <Box key={index} sx={{ marginBottom: "10px" }}>
                      <Typography variant="body2">
                        Version {feedback.feedback_version} (Reviewer:{" "}
                        {feedback.reviewer_id})
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {feedback.feedback_text}
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        Submitted on:{" "}
                        {new Date(feedback.created_at).toLocaleDateString()}
                      </Typography>
                    </Box>
                  ))
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    No feedback history available.
                  </Typography>
                )}

                {/* Feedback Section */}
                <Box sx={{ marginTop: "20px" }}>
                  <Typography variant="body1">
                    Confirmation: {question.confirm_count}/1
                  </Typography>
                </Box>

                {/* Feedback Input and Action Buttons */}
                <Box sx={{ marginTop: "20px" }}>
                  <TextField
                    label="Feedback (required if rejecting question)"
                    multiline
                    rows={3}
                    fullWidth
                    value={feedback[question.question_id] || ""}
                    onChange={(e) =>
                      handleFeedbackChange(question.question_id, e.target.value)
                    }
                    sx={{ marginBottom: "10px" }}
                  />
                  <Box sx={{ display: "flex", gap: "10px" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setDialogOpen(true);
                        setDialogAction("confirm");
                        setCurrentQuestionId(question.question_id);
                      }}
                      disabled={question.confirmed}
                    >
                      {question.confirmed ? "Confirmed" : "Accept & Confirm"}
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        setDialogOpen(true);
                        setDialogAction("reject");
                        setCurrentQuestionId(question.question_id);
                      }}
                      disabled={question.feedbackSubmitted}
                    >
                      {question.feedbackSubmitted
                        ? "Feedback Submitted"
                        : "Reject & Submit Feedback"}
                    </Button>
                  </Box>
                </Box>
              </CardContent>
              <Divider
                sx={{
                  marginY: "20px",
                  backgroundColor: "#e0e0e0",
                  height: "2px",
                }}
              />
            </Card>
          ))}
          {/* Add the Dialog Component Here */}
          <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
            <DialogTitle>Confirm Action</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {dialogAction === "confirm"
                  ? "Are you sure you want to confirm and send this question to the real database?"
                  : "Are you sure you want to reject this question and submit feedback?"}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDialogOpen(false)} color="primary">
                Cancel
              </Button>
              <Button
                onClick={() => {
                  if (dialogAction === "confirm") {
                    handleConfirm(currentQuestionId);
                  } else if (dialogAction === "reject") {
                    handleSubmitFeedback(currentQuestionId);
                  }
                  setDialogOpen(false);
                }}
                color="primary"
                autoFocus
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          {loading && <CircularProgress sx={{ marginTop: "20px" }} />}
        </>
      )}
      {hasMore && !loading && (
        <Button
          variant="contained"
          color="primary"
          onClick={handleLoadMore}
          sx={{
            marginTop: "20px",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          Load More
        </Button>
      )}
    </Box>
  );
};

export default AdminReviewCenter;