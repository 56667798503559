import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Keyboard from "react-simple-keyboard";
import CalculateIcon from "@mui/icons-material/Calculate";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import FlagIcon from "@mui/icons-material/Flag";

import MockTestPracticeQuestions from "./MockTestPracticeQuestions";
import MockTestReviewCard from "./MockTestReviewCard";
import "./MockTestQuestionInterface.css";

const practiceQuestions = [
  {
    id: 1,
    type: 1,
    question: "What is the capital of France?",
    options: [
      { option_id: 1, option_text: "Paris", is_correct: true },
      { option_id: 2, option_text: "London", is_correct: false },
      { option_id: 3, option_text: "Berlin", is_correct: false },
      { option_id: 4, option_text: "Madrid", is_correct: false },
      { option_id: 5, option_text: "Lisbon", is_correct: false },
    ],
  },
  {
    id: 2,
    type: 2,
    question: "What is 2 + 2?",
    correctAnswer: "4",
  },
];

const MockTestPracticeInterface = ({ onComplete }) => {
  const [trace, setTrace] = useState(0);
  const [highlightSubmit, setHighlightSubmit] = useState(false);
  const [highlightNav, setHighlightNav] = useState(false);
  const [highlightReviewCard, setHighlightReviewCard] = useState(true);
  const [highlightConfirm, setHighlightConfirm] = useState(false); // ✅ Highlight confirm button

  // ✅ Timer State (1 hour 30 minutes = 5400 seconds)
  const [timeLeft, setTimeLeft] = useState(5400); // 90 minutes

  // ✅ Timer Effect
  useEffect(() => {
    if (timeLeft <= 0) {
      onComplete(); // Auto-submit when timer reaches 0
      return;
    }

    const timer = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft, onComplete]);

  // ✅ Format time (HH:MM:SS)
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(Math.floor(minutes / 60)).padStart(2, "0")}:${String(
      minutes % 60
    ).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
  };

  const [isSubmitted, setIsSubmitted] = useState(
    Array(practiceQuestions.length).fill(false)
  );
  const [checkedAnswers, setCheckedAnswers] = useState(
    Array(practiceQuestions.length).fill(null)
  );
  const [flags, setFlags] = useState(
    Array(practiceQuestions.length).fill(false)
  );
  const [isLoading, setIsLoading] = useState(false);
  const [reviewCardOpen, setReviewCardOpen] = useState(false); // ✅ State for review card

  // ✅ Calculator State
  const [isCalculatorOpen, setIsCalculatorOpen] = useState(false);
  const [calculatorInput, setCalculatorInput] = useState("");

  const toggleCalculator = () => setIsCalculatorOpen(!isCalculatorOpen);
  const evaluateExpression = () => {
    try {
      setCalculatorInput(eval(calculatorInput).toString());
    } catch {
      setCalculatorInput("Error");
    }
  };

  const handleKeyPress = (button) => {
    if (button === "=") {
      evaluateExpression();
    } else if (button === "C") {
      setCalculatorInput("");
    } else {
      setCalculatorInput((prev) => prev + button);
    }
  };

  const onChecked = (questionId, optionId) => {
    if (!isSubmitted[trace]) {
      setCheckedAnswers((prevAnswers) => {
        const updatedAnswers = [...prevAnswers];
        updatedAnswers[trace] = {
          question_id: questionId,
          option_id: optionId,
        };
        return updatedAnswers;
      });

      // ✅ Ensure the submit button is highlighted
      setHighlightSubmit(true);
    }
  };
  const handleSubmit = () => {
    setIsSubmitted((prev) => {
      const updated = [...prev];
      updated[trace] = true;
      return updated;
    });

    // ✅ Remove confirm and submit button highlight
    setHighlightConfirm(false);
    setHighlightSubmit(false);

    // ✅ Highlight navigation button
    setHighlightNav(true);

    // ✅ Remove navigation highlight after 2 seconds
    setTimeout(() => setHighlightNav(false), 2000);
  };

  return (
    <Box
      component="main"
      sx={{ flexGrow: 1, height: "100vh", overflow: "auto", p: 2 }}
    >
      <Container
        maxWidth="1600px"
        sx={{ mt: 4, mb: 4, height: "calc(100vh - 8rem)" }}
      >
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "80vh",
            position: "relative",
            overflow: "auto",
          }}
        >
          {/* ✅ Practice Questions Header */}
          <Box
            sx={{
              textAlign: "center",
              fontFamily: "Cabin, sans-serif",
              fontSize: "24px",
              fontWeight: "bold",
              padding: "16px",
              backgroundColor: "#f8f9fa",
              borderBottom: "2px solid #ddd",
            }}
          >
            Practice Questions
          </Box>
          {/* ✅ Navigation Bar */}
          <div className="nav-button-container">
            <div className="inner-container">
              <div
                className="question-counter"
                style={{ fontFamily: "Cabin, sans-serif" }}
              >
                Question {trace + 1} of {practiceQuestions.length}
                {/* ✅ Flag Button */}
                <IconButton
                  onClick={() =>
                    setFlags((prev) => {
                      const newFlags = [...prev];
                      newFlags[trace] = !newFlags[trace];
                      return newFlags;
                    })
                  }
                  style={{ color: flags[trace] ? "red" : "inherit" }}
                >
                  <FlagIcon />
                </IconButton>
                {/* ✅ Timer Display (Next to Calculator) */}
                {/* ✅ Calculator Button */}
                <IconButton
                  className="calculator-button"
                  onClick={toggleCalculator}
                  sx={{
                    ml: 0.2,
                    backgroundColor: "#007BFF",
                    color: "white",
                    "&:hover": { backgroundColor: "#0056b3" },
                  }}
                >
                  <CalculateIcon sx={{ fontSize: "1.3rem" }} />
                </IconButton>
                <span
                  className="timer"
                  style={{
                    marginLeft: "20px",
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "red",
                  }}
                >
                  ⏳ {formatTime(timeLeft)}
                </span>
              </div>

              {/* ✅ Navigation Buttons */}
              <Box className="mui-nav-box-root">
                {trace > 0 && (
                  <IconButton
                    onClick={() => setTrace(trace - 1)}
                    aria-label="previous"
                  >
                    <ArrowBackIosIcon />
                  </IconButton>
                )}
                {trace < practiceQuestions.length - 1 ? (
                  <IconButton
                    onClick={() => setTrace(trace + 1)}
                    aria-label="next"
                    className={highlightNav ? "highlight-nav" : ""}
                  >
                    <ArrowForwardIosIcon />
                  </IconButton>
                ) : (
                  <button className="btn results" onClick={onComplete}>
                    Complete Practice
                  </button>
                )}
              </Box>
            </div>
          </div>

          {/* ✅ Calculator Modal */}
          <Dialog open={isCalculatorOpen} onClose={toggleCalculator}>
            <DialogTitle>Calculator</DialogTitle>
            <DialogContent>
              <TextField
                variant="outlined"
                fullWidth
                value={calculatorInput}
                onChange={(e) => setCalculatorInput(e.target.value)}
                sx={{ mb: 2 }}
              />
              <Keyboard
                onKeyPress={handleKeyPress}
                layout={{
                  default: ["7 8 9 /", "4 5 6 *", "1 2 3 -", "0 . = +", "C"],
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={toggleCalculator} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>

          {/* ✅ Content */}
          <div className="parent-content-container">
            <div className="content-container">
              <MockTestPracticeQuestions
                questionObj={practiceQuestions[trace]}
                onChecked={onChecked}
                isSubmitted={isSubmitted[trace]}
                setHighlightConfirm={setHighlightConfirm} // ✅ Pass highlight state
              />
            </div>

            {/* ✅ Review Card */}
            <div
              className="review-card-container"
              style={{ width: reviewCardOpen ? "300px" : "50px" }}
            >
              <MockTestReviewCard
                trace={trace}
                isSubmitted={isSubmitted}
                flags={flags}
                onToggle={(isOpen) => {
                  setReviewCardOpen(isOpen);
                  setHighlightReviewCard(false); // ✅ Remove highlight once clicked
                }}
                onQuestionClick={(qNumber) => setTrace(qNumber - 1)}
                highlightReviewCard={highlightReviewCard} // ✅ Pass the highlight state
              />
            </div>
          </div>

          {/* ✅ Submit Button */}
          <div className="submit-button-container">
            <button
              className={`btn submit ${
                highlightSubmit ? "highlight-button" : ""
              }`}
              onClick={handleSubmit}
              disabled={isSubmitted[trace]}
              style={{ marginRight: "50px" }}
            >
              Submit
            </button>
          </div>
        </Paper>
      </Container>
    </Box>
  );
};

export default MockTestPracticeInterface;
