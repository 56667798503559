import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Thunk to fetch subcategories (or any async logic you want)
export const fetchSubcategories = createAsyncThunk(
    'subcategories/fetchSubcategories',
    async (metadata) => {
        // If you need to perform some async operation here
        return metadata.subcategories; // Or fetch from an API
    }
);

const subcategoriesListSlice = createSlice({
    name: 'subcategoriesList',
    initialState: {
        subcategories: {}, // Empty object as default
        status: 'idle',
        error: null,
    },
    reducers: {
        setSubcategories: (state, action) => {
            state.subcategories = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSubcategories.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchSubcategories.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.subcategories = action.payload;
            })
            .addCase(fetchSubcategories.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export const { setSubcategories } = subcategoriesListSlice.actions;

export default subcategoriesListSlice.reducer;
