import React, { useState, useEffect } from "react";
import "./AnimatedBlob.css";

const getRandomPosition = () => ({
  top: Math.random() * 80, // Random position within 80% of the screen height
  left: Math.random() * 80, // Random position within 80% of the screen width
});

const getRandomVelocity = () => ({
  x: (Math.random() - 0.5) * 0.2, // Small movement speed
  y: (Math.random() - 0.5) * 0.2,
});

const AnimatedBlob = ({ image, className }) => {
  const [position, setPosition] = useState(getRandomPosition()); // 👈 Random initial position
  const [velocity, setVelocity] = useState(getRandomVelocity());

  useEffect(() => {
    let animationFrameId;

    const moveBlob = () => {
      setPosition((prev) => {
        let newTop = prev.top + velocity.y;
        let newLeft = prev.left + velocity.x;

        if (newTop < 5 || newTop > 80) setVelocity((v) => ({ ...v, y: -v.y }));
        if (newLeft < 5 || newLeft > 80)
          setVelocity((v) => ({ ...v, x: -v.x }));

        return { top: newTop, left: newLeft };
      });

      animationFrameId = requestAnimationFrame(moveBlob);
    };

    animationFrameId = requestAnimationFrame(moveBlob);
    return () => cancelAnimationFrame(animationFrameId);
  }, [velocity]);

  return (
    <div
      className={`blob ${className}`}
      style={{
        top: `${position.top}vh`,
        left: `${position.left}vw`,
      }}
    >
      <img src={image} alt="Animated Blob" />
    </div>
  );
};

export default AnimatedBlob;
