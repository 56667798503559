import axios from "axios";
axios.defaults.withCredentials = true;
const baseURL = 'https://preclinique.co.uk/api'; //development server

//const baseURL = 'http://localhost:8000/api'; //localhost

//NODE_ENV = 'development'
//NODE_ENV = 'production'


export async function onRegistration(registrationData) {
    return await axios.post(
        `${baseURL}/register`,
        registrationData
    );
}


export async function getSubId(userId) {
    try {
        const response = await axios.get(`${baseURL}/get-subscription-id/${userId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
}


export async function stripeCheckoutMonthly(userId) {
    return await axios.post(
        `${baseURL}/create-stripe-checkout-session-for-monthly`,
        { userId: userId }
    );
}


export async function stripeCheckoutYearly(userId) {
    return await axios.post(
        `${baseURL}/create-stripe-checkout-session-for-yearly`,
        { userId: userId }
    );
}



export async function getSubscriptionStatus() {
    try {
        const response = await axios.get(`${baseURL}/subscription/status`);
        return response.data;
    } catch (error) {
        throw error;
    }
}


export async function getPaidStatus(userId) {
    try {
        const response = await axios.get(`${baseURL}/getPaidStatus/${userId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
}


export async function cancelSubscription(subscriptionId) {
    try {
        const response = await axios.post(`${baseURL}/cancel-subscription/${subscriptionId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
}




export async function onLogin(loginData) {
    return await axios.post(`${baseURL}/login`, loginData);
}

export async function onLogout() {
    return await axios.get(`${baseURL}/logout`);
}

export async function fetchProtectedInfo() {
    return await axios.get(`${baseURL}/protected`);
}

export async function getUserDetails() {
    return await axios.get(`${baseURL}/getUserDetails`);
}

export async function updateUserDetails(updateData) {
    return await axios.put(
        `${baseURL}/updateUserDetails`,
        updateData
    );
}

export async function initiatePasswordReset(emailData) {
    return await axios.post(
        `${baseURL}/reset-password/initiate`,
        emailData
    );
}

export async function resetPassword(passwordData) {
    return await axios.post(
        `${baseURL}/reset-password/insert`,
        passwordData
    );
}

export async function updatePassword(passwordData) {
    return await axios.put(
        `${baseURL}/updatePassword`,
        passwordData
    );
}

export async function confirmEmail(confirmation_token) {
    return await axios.get(
        `${baseURL}/confirm-account?confirmation_token=${confirmation_token}`
    );
}

export async function getAllMainCategoriesWithQuestionCount(
    universityName,
    courseName
) {
    return await axios.get(
        `${baseURL}/question-bank/categories?university_name=${encodeURIComponent(
            universityName
        )}&course_name=${encodeURIComponent(courseName)}`
    );
}

export async function getSubcategoriesByMainCategoryWithQuestionCount(
    categoryId,
    userId
) {
    return await axios.get(
        `${baseURL}/question-bank/categories/${categoryId}/subcategories?userId=${userId}`
    );
}

export async function getQuestionsBySubcategories(
    subcategoryIds,
    //userId,
    type = 1,
    excludedQuestionIds = []
) {
    console.log("Received parameters:");
    console.log("subcategoryIds:", subcategoryIds);
    //console.log("userId:", userId);
    console.log("type:", type);
    console.log("excludedQuestionIds:", excludedQuestionIds);

    const queryParams = new URLSearchParams({
        subcategories: subcategoryIds.join(","), // Convert array to comma-separated string
        //userId: userId,
        type: type,
        excludedQuestionIds: excludedQuestionIds.join(","), // Convert array to comma-separated string
    });

    const url = `${baseURL}/question-bank/questions?${queryParams}`;

    console.log("Constructed URL:", url);

    return await axios.get(url);
}

export async function getQuestionsToExclude(userId) {
    return await axios.get(
        `${baseURL}/question-bank/getQuestionsToExclude?user_id=${userId}`
    );
}

export async function getQuestionsByIds(questionIds, userId) {
    const questionsQuery = questionIds.join(",");
    return await axios.get(
        `${baseURL}/question-bank/getQuestionsByIds?questions=${questionsQuery}&userId=${userId}`
    );
}

export async function getMockQuestions(course, university_name) {
    return await axios.get(
        `${baseURL}/question-bank/getMockQuestionsByUniversity?university_name=${university_name}&course=${course}`
    );
}


export async function submitMockQuestionData(question_id, chosen_option_id, is_correct) {
    const data = { question_id, chosen_option_id, is_correct };

    try {
        const response = await axios.post(
            `${baseURL}/question-bank/submitMockQuestionData`,
            data
        );
        return response.data; // Return the response from the backend
    } catch (error) {
        console.error("Error submitting mock question data:", error);
        throw error; // Rethrow error to be handled by the calling function
    }
}



export async function submitLongFormatQuestionInMockForReview(submissionData) {
    const data = submissionData; // Spread the submission data into the request body

    try {
        const response = await axios.post(
            `${baseURL}/question-bank/submitLongFormatQuestionInMockForReview`,
            data
        );
        return response.data; // Return the response from the backend
    } catch (error) {
        console.error("Error submitting long-format question for review:", error);
        throw error; // Rethrow error to be handled by the calling function
    }
}


export async function getUserLongFormatMockSubmissions(userId) {
    try {
        const response = await axios.get(
            `${baseURL}/question-bank/getUserLongFormatMockSubmissions/?user_id=${userId}`
        );
        return response.data; // Return the response from the backend
    } catch (error) {
        console.error("Error fetching user submissions:", error);
        throw error; // Rethrow error to be handled by the calling function
    }
}

export async function getMaxPossibleLongFormatScores(universityName) {
    try {
        const response = await axios.get(
            `${baseURL}/question-bank/getMaxPossibleLongFormatScores/?university_name=${universityName}`
        );
        return response.data; // Return the response from the backend
    } catch (error) {
        console.error("Error fetching max possible long format scores:", error);
        throw error; // Rethrow error to be handled by the calling function
    }
}



export async function countType1QuestionsForMock(universityName) {
    try {
        const response = await axios.get(
            `${baseURL}/question-bank/countType1QuestionsForMock/?university_name=${universityName}`
        );
        return response.data; // Return the response from the backend
    } catch (error) {
        console.error("Error counting Type 1 questions:", error);
        throw error; // Rethrow error to be handled by the calling function
    }
}




export async function markQuestionAsCorrect(userId, questionId, mark) {
    try {
        const response = await axios.put(
            `${baseURL}/question-bank/markLongQuestionAsCorrect`,
            { user_id: userId, question_id: questionId, mark: mark } // Include mark in the payload
        );
        return response.data;
    } catch (error) {
        console.error("Error marking question as correct:", error);
        throw error;
    }
}





export async function markQuestionAsIncorrect(userId, questionId) {
    try {
        const response = await axios.put(
            `${baseURL}/question-bank/markLongQuestionAsIncorrect`,
            { user_id: userId, question_id: questionId }
        );
        return response.data; // Return the response from the backend
    } catch (error) {
        console.error("Error marking question as incorrect:", error);
        throw error; // Rethrow error to be handled by the calling function
    }
}



export async function submitMockQuestionResults(userId, answers) {
    try {
        const response = await axios.post(
            `${baseURL}/question-bank/submitMockQuestionResults`,
            { user_id: userId, short_format_answers: answers }
        );
        return response.data; // Return the response from the backend
    } catch (error) {
        console.error("Error submitting mock question results:", error);
        throw error; // Rethrow error to be handled by the calling function
    }
}



export async function getUserMockQuestionResults(userId) {
    try {
        const response = await axios.get(
            `${baseURL}/question-bank/getUserMockQuestionResults?user_id=${userId}`
        );
        return response.data; // Return the response from the backend
    } catch (error) {
        console.error("Error fetching user mock question results:", error);
        throw error; // Rethrow error to be handled by the calling function
    }
}


export async function checkUserMockQuestionResults(userId) {
    try {
        const response = await axios.get(
            `${baseURL}/question-bank/checkUserMockQuestionResults?user_id=${userId}`
        );
        return response.data; // Return the response from the backend
    } catch (error) {
        console.error("Error checking user mock question results:", error);
        throw error; // Rethrow error to be handled by the calling function
    }
}

export async function getUserLongFormatScores(userId) {
    try {
        const response = await axios.get(
            `${baseURL}/question-bank/getUserLongFormatScores?user_id=${userId}`
        );
        return response.data; // Return the response from the backend
    } catch (error) {
        console.error("Error fetching user long format scores:", error);
        throw error; // Rethrow error to be handled by the calling function
    }
}



export async function hasUserSubmittedMock(userId) {
    try {
        const response = await axios.get(
            `${baseURL}/question-bank/hasUserSubmittedMock?user_id=${userId}`
        );
        return response.data; // Return the response from the backend
    } catch (error) {
        console.error("Error checking user mock status:", error);
        throw error; // Rethrow error to be handled by the calling function
        }
        }


export async function hasUserSubmittedShortFormat(userId) {
    try {
        const response = await axios.get(
            `${baseURL}/question-bank/hasUserSubmittedShortFormat?user_id=${userId}`
        );
        return response.data; // Return the response from the backend
    } catch (error) {
        console.error("Error checking user mock status:", error);
        throw error; // Rethrow error to be handled by the calling function
        }
        }
    




export async function markMockAsComplete(userId) {
    try {
        const response = await axios.put(
            `${baseURL}/question-bank/markMockAsComplete`,
            { user_id: userId }
        );
        return response.data; // Return the response from the backend
    } catch (error) {
        console.error("Error marking mock as complete:", error);
        throw error; // Rethrow error to be handled by the calling function
    }
}






//user progress stuff
export async function saveUserProgress(userId, subcatIds, lastQuestionIds) {
    const data = {
        userId,
        subcatIds,
        lastQuestionIds,
    };

    return await axios.post(
        `${baseURL}/userProgress/saveProgress`,
        data
    );
}

export async function getUserProgress(userId) {
    return await axios.get(`${baseURL}/userProgress/${userId}`);
}

export async function updateTimestamp(userId) {
    return await axios.post(
        `${baseURL}/userProgress/updateTimestamp/${userId}`
    );
}

//farqaans endpoints
export async function completeQuestion(userId, questionId, completed, result) {
    const data = {
        user_id: userId, // Make sure userId is not null
        question_id: questionId, // Make sure questionId is not null
        completed: completed,
        result: result,
    };
    console.log("Data to be sent:", data);

    return await axios.post(
        `${baseURL}/question-bank/completeQuestion`,
        data,
        {
            headers: {
                "Content-Type": "application/json", // Set the content type to JSON
            },
        }
    );
}

export async function resetAllProgress(userId) {
    return await axios.post(
        `${baseURL}/question-bank/resetAllProgress/${userId}`
    );
}

export async function flagQuestion(userId, questionId, flagged, timestamp) {
    const data = {
        user_id: userId, // Make sure userId is not null
        question_id: questionId,
        timestamp,
    };

    return await axios.post(
        `${baseURL}/question-bank/flagQuestion`,
        data
    );
}

export async function unflagQuestion(userId, questionId) {
    const data = {
        user_id: userId, // Make sure userId is not null
        question_id: questionId,
    };

    return await axios.post(
        `${baseURL}/question-bank/unflagQuestion`,
        data
    );
}

export async function reviewFlaggedQuestions(userId) {
    return await axios.get(
        `${baseURL}/question-bank/reviewFlaggedQuestions/${userId}`
    );
}

export async function getAllScoresForPercentile() {
    return await axios.get(
        `${baseURL}/question-bank/getAllScoresForPercentile`
    );
}

export async function getAllUserScores() {
    return await axios.get(
        `${baseURL}/question-bank/getAllUserScores`
    );
}

export async function getUserAverageScore(userId) {
    return await axios.get(
        `${baseURL}/question-bank/getUserAverageScore?user_id=${userId}`
    );
}
export async function getScoresFromCourse(course, universityName) {
    const queryParams = universityName
        ? `?course=${course}&university_name=${universityName}`
        : `?course=${course}`;

    return await axios.get(
        `${baseURL}/question-bank/getScoresFromCourse${queryParams}`
    );
}

export async function getScoresFromYear(class_year) {
    return await axios.get(
        `${baseURL}/question-bank/getScoresFromYear?class_year=${class_year}`
    );
}

export async function removeFromReview(userId, questionId) {
    const data = {
        user_id: userId, // Make sure userId is not null
        question_id: questionId,
    };

    return await axios.post(
        `${baseURL}/question-bank/removeFromReview`,
        data
    );
}

export async function mockTestQuestions() {
    return await axios.get(
        `${baseURL}/question-bank/mockTestQuestions`
    );
}

export async function retrieveFromReview(userId, catId = "", subcatId = "") {
    return await axios.get(
        `${baseURL}/question-bank/retrieveFromReview?user_id=${userId}&cat_id=${catId}&subcat_id=${subcatId}`
    );
}

export async function submitTest(
    userId,
    universityName,
    classYear,
    score,
    courseName
) {
    const data = {
        user_id: userId,
        university_name: universityName,
        class_year: classYear,
        score: score,
        course: courseName,
    };

    return await axios.post(
        `${baseURL}/question-bank/submitTest`,
        data
    );
}

export async function insertForReview(
    userId,
    questionId,
    questionText, // Add question_text parameter
    userOptionText,
    correctOptionText,
    correct
) {
    const data = {
        user_id: userId,
        question_id: questionId,
        question_text: questionText, // Include question_text in data
        user_option_text: userOptionText,
        correct_option_text: correctOptionText,
        correct,
    };
    try {
        const response = await axios.post(
            `${baseURL}/question-bank/insertForReview`,
            data
        );
        return response;
    } catch (error) {
        console.error(
            "Error in Axios call:",
            error.response ? error.response.data : error
        );
        throw error; // Re-throw the error so it can be caught where the function is called
    }
}

export async function howManyQuestionsAreThereTotal(course, university_name) {
    const queryParams = university_name
        ? `?course=${course}&university_name=${university_name}`
        : `?course=${course}`;

    return await axios.get(
        `${baseURL}/question-bank/howManyQuestionsAreThereTotal${queryParams}`
    );
}

export async function howManyQuestionsCorrect(user_id) {
    return await axios.get(
        `${baseURL}/question-bank/howManyQuestionsCorrect?user_id=${user_id}`
    );
}

export async function howManyQuestionsIncorrect(user_id) {
    return await axios.get(
        `${baseURL}/question-bank/howManyQuestionsIncorrect?user_id=${user_id}`
    );
}

export async function howManyQuestionsAttempted(user_id) {
    return await axios.get(
        `${baseURL}/question-bank/howManyQuestionsAttempted?user_id=${user_id}`
    );
}

export async function resumeSession(userId) {
    return await axios.get(
        `${baseURL}/question-bank/resumeSession?user_id=${userId}`
    );
}

export async function saveSession(data) {
    console.log("datatobesent", data);
    return await axios.post(
        `${baseURL}/question-bank/saveSession`,
        data,
        {
            headers: {
                "Content-Type": "application/json", // Set the content type to JSON
            },
        }
    );
}

export async function deleteSession(userId) {
    return await axios.delete(
        `${baseURL}/question-bank/deleteSession?user_id=${userId}`
    );
}


export async function reportQuestionInsert(
    questionId,
    problem,
    university,
    course,
    category,
    subcategory,
    date
) {
    const data = {
        questionId,  // Match key names
        problem,
        university,
        course,
        category,
        subcategory,
        date,
    };

    try {
        const response = await axios.post(`${baseURL}/question-bank/reportQuestionInsert`, data);
        return response;
    } catch (error) {
        console.error(
            "Error in Axios call:",
            error.response ? error.response.data : error
        );
        throw error;
    }
}


export async function getQuestionImageLink(questionId) {
    return await axios.get(
        `${baseURL}/question-bank/getQuestionImageLink?question_id=${questionId}`
    );
}

export async function getQuestionImageLinkForMock(questionId) {
    return await axios.get(
        `${baseURL}/question-bank/getQuestionImageLinkForMock?question_id=${questionId}`
    );
}
//commen


//comments

// In your frontend axios file (auth.js or similar)
export async function getCommentsByQuestionId(questionId, userId) {
    return await axios.get(
        `${baseURL}/question-bank/questions/${questionId}/comments`,
        {
            params: { userId },
        }
    );
}

export async function addComment(commentData) {
    const { question_id, user_id, comment_text, parent_comment_id } = commentData;
    // Update the URL to match the correct base path
    return await axios.post(
        `${baseURL}/question-bank/questions/${question_id}/comments`,
        {
            user_id,
            comment_text,
            parent_comment_id,
        }
    );
}

export async function likeComment(commentData) {
    const { user_id, comment_id, is_like } = commentData;
    return await axios.post(`${baseURL}/question-bank/comments/like`, {
        user_id,
        comment_id,
        is_like, // true for like, false for dislike
    });
}


    //portal endpoints start here

   export async function getQuestionsForReview(
reviewerId,
limit = 20,
offset = 0
) {
    try {
    console.log(
    `Fetching questions: reviewerId=${reviewerId}, limit=${limit}, offset=${offset}`
    );
    const response = await axios.get(
    `${baseURL}/question-bank/questions-for-review`,
    {
    params: { reviewer_id: reviewerId, limit, offset },
    }
    );
    console.log("API Response Data:", response.data);
    return response.data;
    } catch (error) {
    console.error("Error fetching questions for review:", error);
    throw error;
}
}
// Function to submit a review
export async function submitReview({
question_id,
reviewer_id,
is_confirmed,
feedback_text,
}) {

try {
const response = await axios.post(
`${baseURL}/question-bank/submit-review`,
{
question_id,
reviewer_id,
is_confirmed,
feedback_text,
}
);
return response.data;
} catch (error) {
console.error(
"Error submitting review:",
error.response ? error.response.data : error.message
);
throw error;
}
}
//uploading questions
export async function uploadQuestion(formData) {
    console.log("formdata", formData);
try {
const response = await axios.post(
`${baseURL}/question-bank/upload`,
formData
);
console.log("aaa", formData);
return response.data;
} catch (error) {
console.error("Error uploading question:", error.message);
throw error;
}
}
// Function to fetch reviewed questions
export async function getReviewedQuestions(uploaderId) {
try {
console.log(`Fetching reviewed questions for uploaderId=${uploaderId}`);

const response = await axios.get(
`${baseURL}/question-bank/reviewed-questions`,
{
params: { uploader_id: uploaderId },
}
);
console.log("API Response Data:", response.data);
// Map through the reviewed questions without attempting to re-parse JSON objects
const reviewedQuestions = response.data.reviewedQuestions.map(
(question) => ({
...question,
// Ensure choices and feedback_history are already parsed JSON arrays
choices: Array.isArray(question.choices)
? question.choices
: JSON.parse(question.choices),
feedback_history: Array.isArray(question.feedback_history)
? question.feedback_history
: JSON.parse(question.feedback_history),
})
);
return { ...response.data, reviewedQuestions };
} catch (error) {
console.error("Error fetching reviewed questions:", error);
throw error;
}
}
export async function getConfirmedQuestionsCount(uploaderId) {
try {
const response = await axios.get(
`${baseURL}/question-bank/confirmed-questions-count`,
{ params: { uploader_id: uploaderId } }
);

return response.data;
} catch (error) {
console.error("Error fetching confirmed questions count:", error.message);
throw error;
}
}
export async function uploadChoice(choiceData) {
try {
const response = await axios.post(
`${baseURL}/question-bank/upload-choice`,
choiceData
);
return response.data;
} catch (error) {
console.error("Error uploading choice:", error.message);
throw error;
}
}
export async function updateQuestion(updatedQuestionData) {
try {
const response = await axios.put(
`${baseURL}/question-bank/update-question`,
updatedQuestionData
);
return response.data;
} catch (error) {
console.error("Error updating question:", error.message);
throw error;
}
}
export async function addCategory(data) {
try {
const response = await axios.post(
`${baseURL}/question-bank/add-category`,

data
); // Prepend baseURL
return response.data;
} catch (error) {
console.error("Error adding category:", error.message);
throw error;
}
}
export async function addSubcategory(data) {
try {
const response = await axios.post(
`${baseURL}/question-bank/add-subcategory`,
data
); // Prepend baseURL
return response.data;
} catch (error) {
console.error("Error adding subcategory:", error.message);
throw error;
}
}
export const getReviewedQuestionsCount = async (reviewerId) => {
try {
const response = await axios.get(
`${baseURL}/question-bank/reviewed-questions-count`,
{ params: { reviewer_id: reviewerId } }
);
return response.data.reviewedCount;
} catch (error) {
console.error("Error fetching reviewed questions count:", error.message);
throw error;
}
};
export async function getCategories(universityName) {
try {
const response = await axios.get(

`${baseURL}/question-bank/categories/all?university_name=${encodeURIComponent(
universityName
)}`
);
return response.data.categories;
} catch (error) {
console.error("Error fetching categories:", error.message);
throw error;
}
}
export async function getSubcategories(universityName, courseName) {
try {
const response = await axios.get(
`${baseURL}/question-bank/subcategories/all`,
{
params: {
university_name: universityName,
course_name: courseName, // Ensure correct parameter is passed
},
}
);
return response.data.subcategories;
} catch (error) {
console.error("Error fetching subcategories:", error.message);
throw error;
}
}
export const updateAvatarAPI = async (userId, avatarPath) => {
try {
const response = await axios.post(`${baseURL}/update-avatar`, {
userId,
avatarPath,
});
return response.data;
} catch (error) {

console.error("Error updating avatar:", error);
throw error;
}
};