import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const ReviewerRoutes = () => {
  const { isAuth, role } = useSelector((state) => state.auth);

  if (!isAuth) {
    return <Navigate to="/login" />;
  }

  if (role !== "reviewer") {
    return <Navigate to="/dashboard/homepage" />;
  }

  return <Outlet />;
};

export default ReviewerRoutes;