import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateAvatarAPI } from "../../../../api/auth";
import { updateAvatar } from "../../../../redux/slices/authSlice";
import { Box, Button, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import RuleIcon from "@mui/icons-material/Rule";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const avatars = [
  "/avatars/avatar1.svg",
  "/avatars/avatar2.svg",
  "/avatars/avatar3.svg",
  "/avatars/avatar4.svg",
  "/avatars/avatar5.svg",
  "/avatars/avatar6.svg",
  "/avatars/avatar7.svg",
  "/avatars/avatar8.svg",
];

const ChangeAvatar = () => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.auth.userId);
  const [selectedAvatar, setSelectedAvatar] = useState(avatars[0]);

  const handleAvatarChange = (direction) => {
    const currentIndex = avatars.indexOf(selectedAvatar);
    const newIndex =
      (currentIndex + direction + avatars.length) % avatars.length;
    setSelectedAvatar(avatars[newIndex]);
  };

  const handleSaveAvatar = async () => {
    try {
      await updateAvatarAPI(userId, selectedAvatar);
      dispatch(updateAvatar(selectedAvatar)); // Update Redux state
      alert("Avatar updated successfully!");
    } catch (error) {
      console.error("Failed to save avatar", error);
      alert("Failed to update avatar. Please try again.");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Typography
        sx={{ textAlign: "center", width: "100%" }}
        variant="h5"
        gutterBottom
        className="headingStyle"
      >
        Change Your Avatar
      </Typography>
      <Avatar
        src={selectedAvatar}
        alt="Selected Avatar"
        sx={{ width: 250, height: 300, marginBottom: 3, marginTop: 1 }}
      />
      <Box sx={{ display: "flex", justifyContent: "center", marginBottom: 2 }}>
        <Button onClick={() => handleAvatarChange(-1)}>Previous</Button>
        <Button onClick={() => handleAvatarChange(1)}>Next</Button>
      </Box>
      <Button variant="contained" onClick={handleSaveAvatar}>
        Save Avatar
      </Button>
    </Box>
  );
};

export default ChangeAvatar;
