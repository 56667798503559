import React, { useState, useEffect } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import "./MockTestReviewCard.css";
import FlagIcon from "@mui/icons-material/Flag";

const MockTestReviewCard = ({
  trace,
  isSubmitted,
  isCorrect,
  onToggle,
  onQuestionClick,
  flags,
  highlightReviewCard, // ✅ Add this prop
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [questionsReviewed, setQuestionsReviewed] = useState([]);

  useEffect(() => {
    if (!questionsReviewed.includes(trace + 1)) {
      setQuestionsReviewed([...questionsReviewed, trace + 1]);
    }
  }, [trace, questionsReviewed]);

  const toggleDrawer = () => {
    const nextState = !isOpen;
    setIsOpen(nextState);
    onToggle(nextState); // Inform parent of the state change
  };

  return (
    <div className={`review-card ${isOpen ? "open" : "closed"}`}>
      <button
        className={`toggle-button ${
          highlightReviewCard ? "highlight-review" : ""
        }`}
        onClick={toggleDrawer}
      >
        {isOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
      </button>

      <h4>Review Card</h4>
      <div className="questions-list">
        {questionsReviewed.map((questionNumber) => (
          <div
            key={questionNumber}
            className="question-item"
            onClick={() => onQuestionClick(questionNumber)}
          >
            <span className="question-number">{questionNumber}</span>
            <div className="icon-container">
              {flags[questionNumber - 1] && <span className="tick">🚩</span>}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MockTestReviewCard;
