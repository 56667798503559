import React, { useEffect, useState } from "react";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Collapse from "@mui/material/Collapse";
import "./MockTestQuestions.css";

import { getQuestionImageLink } from "../../../../../api/auth";

const MockTestPracticeQuestions = ({
  questionObj,
  onChecked,
  isSubmitted,
  setHighlightConfirm,
}) => {
  const [checkedAnswers, setCheckedAnswers] = useState({});
  const [textboxValue, setTextboxValue] = useState("");
  const [image, setImage] = useState(null);
  const [buttonText, setButtonText] = useState(
    "Confirm Answer Here Before Submitting"
  );
  const [showHint, setShowHint] = useState(false);

  useEffect(() => {
    const fetchImage = async () => {
      if (questionObj && questionObj.id) {
        const imageLink = await getQuestionImageLink(questionObj.id);
        setImage(imageLink.data.session[0].image);
      }
    };
    fetchImage();
  }, [questionObj]);

  // ✅ Handle textbox click to show hint
  const handleTextboxClick = () => {
    if (!textboxValue) {
      setShowHint(true);
    }
  };

  // ✅ Handle input change to hide hint and highlight confirm button
  const handleTextboxChange = (e) => {
    setTextboxValue(e.target.value);
    setShowHint(false);
    setHighlightConfirm(true); // ✅ Highlight confirm button when user starts typing
  };

  // ✅ Handle confirm answer button click
  const handleTextboxSubmit = () => {
    if (!textboxValue) return; // Prevent submission if empty

    onChecked(questionObj.id, textboxValue);
    setButtonText("Answer Saved... Please Proceed To Submit");

    setHighlightConfirm(false); // ✅ Remove highlight from confirm button
    setTimeout(() => {
      setButtonText("Confirm Answer Here Before Submitting");
    }, 3000);
  };

  function onSelect(optionId, index) {
    if (!isSubmitted) {
      setCheckedAnswers({ ...checkedAnswers, [questionObj.id]: index });
      onChecked(questionObj.id, optionId);
    }
  }

  function renderControl(i, option) {
    const isChecked = checkedAnswers[questionObj.id] === i;
    return (
      <Radio
        checked={isChecked}
        onChange={() => onSelect(option.option_id, i)}
        name={`options-${questionObj.id}`}
        color="primary"
        disabled={isSubmitted} // Disable after submission
      />
    );
  }

  function getOptionClass(i) {
    return isSubmitted && checkedAnswers[questionObj.id] === i
      ? "user-choice"
      : "";
  }

  return (
    <div className="questions">
      <h2
        className="question-text"
        style={{ fontFamily: "Cabin, sans-serif" }} // ✅ Applied Cabin font here
      >
        {questionObj?.question}
      </h2>

      {image && (
        <img
          src={image}
          alt="Question"
          style={{ maxWidth: "100%", height: "auto" }}
        />
      )}

      {questionObj.type === 1 && (
        <FormGroup role="radiogroup">
          {questionObj?.options.map((option, i) => (
            <div
              key={option.option_id}
              className={`option-border-style ${getOptionClass(i)}`}
            >
              <FormControlLabel
                value={option.option_id}
                control={renderControl(i, option)}
                label={option.option_text}
                style={{
                  width: "100%",
                  marginRight: "0px",
                  marginLeft: "0px",
                  paddingRight: "72px",
                  boxSizing: "border-box",
                  paddingTop: "2px",
                  cursor: isSubmitted ? "default" : "pointer",
                  fontFamily: "Cabin, sans-serif", // ✅ Applied Cabin font for options
                }}
              />
            </div>
          ))}
        </FormGroup>
      )}

      {(questionObj.type === 2 || questionObj.type === 3) && (
        <div>
          <input
            type="text"
            placeholder="Enter your answer"
            className="fancy-text-input"
            value={textboxValue}
            onChange={handleTextboxChange} // ✅ Highlight confirm button when typing
            disabled={isSubmitted} // Disable input after submission
            style={{ fontFamily: "Cabin, sans-serif" }} // ✅ Applied Cabin font
            onClick={handleTextboxClick} // ✅ Show hint when clicked
          />
          {showHint && <p className="hint-text">Enter your answer first</p>}{" "}
          {/* ✅ Display hint */}
          <button
            className={`reveal-button ${
              textboxValue ? "highlight-confirm" : ""
            }`} // ✅ Add highlight class
            onClick={handleTextboxSubmit}
            disabled={isSubmitted}
            style={{ fontFamily: "Cabin, sans-serif" }}
          >
            {buttonText}
          </button>
        </div>
      )}
    </div>
  );
};

export default MockTestPracticeQuestions;
