import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  CardContent,
  CircularProgress,
} from "@mui/material";
import { useSelector } from "react-redux";
import {
  getAllMainCategoriesWithQuestionCount,
  getSubcategoriesByMainCategoryWithQuestionCount,
  getCategories,
  getSubcategories,
  addCategory,
  addSubcategory,
  uploadQuestion,
  uploadChoice,
  getReviewedQuestions,
  updateQuestion,
  getConfirmedQuestionsCount,
} from "../../../../api/auth";

const DashboardQuestionUpload = () => {
  const [activeTab, setActiveTab] = useState("upload"); // "upload" or "reviewed"

  const [isCategoryMode, setIsCategoryMode] = useState(false); // Toggle for upload or manage categories
  const [isTableViewMode, setIsTableViewMode] = useState(false); // Toggle for table view
  const [mainCategories, setMainCategories] = useState([]);
  const [subCategories, setSubCategories] = useState({});
  const [categories, setCategories] = useState([]); // For table display
  const [subcategories, setSubcategories] = useState([]); // For table display
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [selectedSubcategoryId, setSelectedSubcategoryId] = useState("");
  const [questionText, setQuestionText] = useState("");
  const [lectureReference, setLectureReference] = useState("");
  const [questionType, setQuestionType] = useState("");
  const [highYield, setHighYield] = useState(false);
  const [image, setImage] = useState(null);
  const [choicesText, setChoicesText] = useState(""); // NEW: Text for all choices
  const [explanationsText, setExplanationsText] = useState(""); // NEW: Text for all explanations
  const [correctChoiceIndex, setCorrectChoiceIndex] = useState(""); // NEW: Correct choice index
  const [confirmedCount, setConfirmedCount] = useState(0);
  const [targetCount, setTargetCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const [editedQuestions, setEditedQuestions] = useState({});

  const { universityName, courseName } = useSelector((state) => state.auth);
  const userId = useSelector((state) => state.auth.userId);

  const [reviewedQuestions, setReviewedQuestions] = useState([]);
  const [loadingReviewedQuestions, setLoadingReviewedQuestions] =
    useState(false);

  // States for adding categories and subcategories
  const [categoryName, setCategoryName] = useState("");
  const [categoryYear, setCategoryYear] = useState("");
  const [subcategoryName, setSubcategoryName] = useState("");

  useEffect(() => {
    async function fetchConfirmedCount() {
      try {
        const response = await getConfirmedQuestionsCount(userId);
        setConfirmedCount(response.confirmedCount);
      } catch (error) {
        console.error(
          "Error fetching confirmed questions count:",
          error.message
        );
      } finally {
        setLoading(false);
      }
    }
    fetchConfirmedCount();
  }, [userId]);

  const handleTargetChange = (event) => {
    const value = parseInt(event.target.value, 10);
    if (!isNaN(value) && value >= 0) {
      setTargetCount(value);
    }
  };

  useEffect(() => {
    async function fetchMainCategories() {
      if (universityName && courseName) {
        try {
          const response = await getAllMainCategoriesWithQuestionCount(
            universityName,
            courseName
          );
          setMainCategories(response.data.mainCategories);
        } catch (error) {
          console.error("Error fetching main categories:", error);
        }
      }
    }
    fetchMainCategories();
  }, [universityName, courseName]);

  useEffect(() => {
    if (selectedCategoryId) {
      const fetchSubcategories = async () => {
        try {
          const response =
            await getSubcategoriesByMainCategoryWithQuestionCount(
              selectedCategoryId,
              userId
            );
          setSubCategories((prev) => ({
            ...prev,
            [selectedCategoryId]: response.data.subcategories,
          }));
        } catch (error) {
          console.error("Error fetching subcategories:", error);
        }
      };
      fetchSubcategories();
    }
  }, [selectedCategoryId, userId]);
  useEffect(() => {
    async function fetchSubcategories() {
      try {
        if (universityName && courseName) {
          const response = await getSubcategories(universityName, courseName);
          console.log("Subcategories Response:", response);
          setSubcategories(response || []); // Ensure state is updated
        } else {
          console.error("Missing universityName or courseName:", {
            universityName,
            courseName,
          });
        }
      } catch (error) {
        console.error("Error fetching subcategories:", error.message);
      }
    }

    fetchSubcategories();
  }, [universityName, courseName]);

  // Handle field changes
  const handleInputChange = (questionId, field, value) => {
    setEditedQuestions((prev) => ({
      ...prev,
      [questionId]: {
        ...prev[questionId],
        [field]: value,
      },
    }));
  };

  const handleChoiceChange = (questionId, choiceIndex, field, value) => {
    setEditedQuestions((prev) => {
      const updatedChoices = [...(prev[questionId]?.choices || [])];
      updatedChoices[choiceIndex] = {
        ...updatedChoices[choiceIndex],
        [field]: value,
      };

      return {
        ...prev,
        [questionId]: {
          ...prev[questionId],
          choices: updatedChoices,
        },
      };
    });
  };

  const validateFields = (fields) => {
    return Object.values(fields).every((value) => {
      if (typeof value === "string") {
        return value.trim() !== ""; // Ensure non-empty strings
      }
      return Boolean(value); // Check for truthy values
    });
  };

  const handleSave = async (questionId) => {
    const questionData = { ...editedQuestions[questionId] };

    // Ensure correctChoiceIndex is synchronized with `choices`
    const correctIndex = parseInt(questionData.correct_choice_index, 10) - 1;
    if (
      isNaN(correctIndex) ||
      correctIndex < 0 ||
      correctIndex >= questionData.choices.length
    ) {
      alert("Invalid correct choice index.");
      return;
    }

    questionData.choices = questionData.choices.map((choice, index) => ({
      ...choice,
      is_correct: index === correctIndex,
    }));

    try {
      const response = await updateQuestion({
        question_id: questionId,
        subcat_id: questionData.subcat_id, // Include subcat_id
        ...questionData,
      });

      if (response.success) {
        alert("Question updated successfully!");
        setReviewedQuestions((prev) =>
          prev.filter((q) => q.question_id !== questionId)
        );
        setEditedQuestions((prev) => {
          const updatedState = { ...prev };
          delete updatedState[questionId];
          return updatedState;
        });
      } else {
        alert("Failed to update question: " + response.error);
      }
    } catch (error) {
      console.error("Error saving question:", error);
      alert("An error occurred while saving the question.");
    }
  };

  // Function to handle category change
  const handleCategoryChange = (event) => {
    setSelectedCategoryId(event.target.value);
    setSelectedSubcategoryId(""); // Reset subcategory selection
  };

  // Function to handle subcategory change
  const handleSubcategoryChange = (event) => {
    setSelectedSubcategoryId(event.target.value);
  };

  // Fetch categories and subcategories for table view
  useEffect(() => {
    async function fetchData() {
      try {
        const categoriesData = await getCategories(universityName);
        setCategories(categoriesData); // The backend now returns the data without duplicates
      } catch (error) {
        console.error("Error fetching table data:", error.message);
      }
    }

    if (isTableViewMode && universityName) {
      fetchData();
    }
  }, [isTableViewMode, universityName]);

  // Fetch reviewed questions
  // Fetch reviewed questions for the "Reviewed Questions" tab
  useEffect(() => {
    async function fetchReviewedQuestions() {
      try {
        if (activeTab === "reviewed") {
          setLoadingReviewedQuestions(true);

          // Fetch reviewed questions
          const reviewedResponse = await getReviewedQuestions(userId);
          const reviewedData = reviewedResponse.reviewedQuestions || [];

          console.log("Reviewed Questions:", reviewedData);

          // Set reviewed questions directly (no need for enrichment as backend provides `subcategory_name`)
          setReviewedQuestions(reviewedData);

          // Initialize `editedQuestions` state based on reviewed questions
          const initialEditedState = {};
          reviewedData.forEach((question) => {
            initialEditedState[question.question_id] = {
              question_text: question.question_text,
              lecture_reference: question.lecture_reference,
              type: question.type,
              high_yield: question.high_yield,
              image: question.image,
              choices: question.choices,
              subcat_id: question.subcat_id, // Use `subcat_id` from reviewed question
            };
          });
          setEditedQuestions(initialEditedState);
        }
      } catch (error) {
        console.error("Error fetching reviewed questions:", error);
      } finally {
        setLoadingReviewedQuestions(false);
      }
    }

    if (universityName && courseName) {
      fetchReviewedQuestions();
    }
  }, [universityName, courseName, activeTab, userId]);

  // Handlers for adding categories and subcategories
  const handleAddCategory = async () => {
    if (!validateFields({ categoryName, categoryYear })) {
      alert("Please fill in all required fields for the category.");
      return;
    }

    try {
      await addCategory({
        cat_name: categoryName,
        university_name: universityName,
        course_name: courseName,
        year: categoryYear,
      });
      alert("Category added successfully! Refresh the page to see updates.");
      setCategoryName("");
      setCategoryYear("");
    } catch (error) {
      console.error("Error adding category:", error.message);
    }
  };

  const handleAddSubcategory = async () => {
    if (!validateFields({ selectedCategoryId, subcategoryName })) {
      alert("Please fill in all required fields for the subcategory.");
      return;
    }

    try {
      await addSubcategory({
        cat_id: selectedCategoryId,
        subcategory_name: subcategoryName,
        course_name: courseName,
        university_name: universityName,
      });
      alert("Subcategory added successfully!");
      setSubcategoryName("");
    } catch (error) {
      console.error("Error adding subcategory:", error.message);
    }
  };

  // Parse choices and explanations from input
  const parseInput = (text) => {
    return text
      .split("\\")
      .map((item) => item.trim())
      .filter((item) => item);
  };

  // Handler for uploading questions and choices
  const handleUpload = async () => {
    if (
      !validateFields({
        selectedSubcategoryId,
        questionText,
        lectureReference,
        questionType,
        choicesText,
        explanationsText,
        correctChoiceIndex,
      })
    ) {
      alert("Please fill in all required fields.");
      return;
    }

    try {
      // Parse choices and explanations
      const parsedChoices = parseInput(choicesText);
      const parsedExplanations = parseInput(explanationsText);

      if (parsedChoices.length !== parsedExplanations.length) {
        alert("Each choice must have a corresponding explanation.");
        return;
      }

      const correctIndex = parseInt(correctChoiceIndex, 10);
      if (
        isNaN(correctIndex) ||
        correctIndex < 1 ||
        correctIndex > parsedChoices.length
      ) {
        alert(
          "Correct choice index must be a valid number between 1 and the number of choices."
        );
        return;
      }

      // Upload the question
      const questionFormData = {
        subcat_id: selectedSubcategoryId,
        question_text: questionText,
        lecture_reference: lectureReference,
        type: questionType,
        high_yield: highYield,
        image,
      };
      const questionResponse = await uploadQuestion(questionFormData);

      if (questionResponse.success) {
        const questionId = questionResponse.question.question_id;

        // Upload each choice
        for (let i = 0; i < parsedChoices.length; i++) {
          const choiceData = {
            question_id: questionId,
            option_text: parsedChoices[i],
            explanation_text: parsedExplanations[i],
            is_correct: i === correctIndex - 1, // Correct index is 1-based
            uploaded_by: userId,
          };
          await uploadChoice(choiceData);
        }

        alert("Question and choices uploaded successfully!");
        resetForm();
      } else {
        alert("Failed to upload question: " + questionResponse.error);
      }
    } catch (error) {
      console.error("Error uploading question and choices:", error.message);
      alert("An error occurred while uploading the question and choices.");
    }
  };

  // Reset form
  const resetForm = () => {
    setQuestionText("");
    setLectureReference("");
    setQuestionType("");
    setHighYield(false);
    setImage(null);
    setChoicesText("");
    setExplanationsText("");
    setCorrectChoiceIndex("");
  };

  const handleUpdate = async (question_id, questionData) => {
    try {
      const {
        question_text,
        lecture_reference,
        type,
        high_yield,
        image,
        choices,
      } = questionData;

      const updatedData = {
        question_id,
        question_text,
        lecture_reference,
        type,
        high_yield,
        image,
        choices,
      };

      const response = await updateQuestion(updatedData);

      if (response.success) {
        alert("Question resubmitted successfully!");

        // Remove the updated question from the reviewed questions
        setReviewedQuestions((prev) =>
          prev.filter((q) => q.question_id !== question_id)
        );
      } else {
        alert("Failed to resubmit question: " + response.error);
      }
    } catch (error) {
      console.error("Error resubmitting question:", error.message);
      alert("An error occurred while resubmitting the question.");
    }
  };

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        {isTableViewMode
          ? "View Categories and Subcategories"
          : isCategoryMode
          ? "Manage Categories & Subcategories"
          : activeTab === "upload"
          ? "Upload Question"
          : "Reviewed Questions"}
      </Typography>

      {/* Toggle Buttons */}
      <Box mb={2}>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setIsCategoryMode((prev) => !prev)}
          disabled={isTableViewMode}
        >
          {isCategoryMode
            ? "Switch to Question Upload"
            : "Switch to Manage Categories"}
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => setIsTableViewMode((prev) => !prev)}
          style={{ marginLeft: "10px" }}
        >
          {isTableViewMode
            ? "Hide Tables"
            : "View Categories and Subcategories"}
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setActiveTab("upload")}
          style={{ marginLeft: "10px" }}
        >
          Upload Question
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => setActiveTab("reviewed")}
          style={{ marginLeft: "10px" }}
        >
          Reviewed Questions
        </Button>
      </Box>

      {isTableViewMode ? (
        <Box>
          <Typography variant="h5">Categories</Typography>
          <TableContainer component={Paper} style={{ marginTop: "16px" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Category ID</TableCell>
                  <TableCell>Category Name</TableCell>
                  <TableCell>University Name</TableCell>
                  <TableCell>Course Name</TableCell>
                  <TableCell>Year</TableCell>
                  <TableCell>Subcategories</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {categories.map((category) => (
                  <TableRow key={category.cat_id}>
                    <TableCell>{category.cat_id}</TableCell>
                    <TableCell>{category.cat_name}</TableCell>
                    <TableCell>{category.university_name}</TableCell>
                    <TableCell>{category.course_name}</TableCell>
                    <TableCell>{category.year}</TableCell>
                    <TableCell>
                      {category.subcategories.map((subcat) => (
                        <div key={subcat.subcat_id}>
                          {subcat.subcategory_name} (ID: {subcat.subcat_id})
                        </div>
                      ))}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ) : isCategoryMode ? (
        <Box mt={4}>
          <Typography variant="h5">Add Category</Typography>
          <TextField
            label="Category Name"
            fullWidth
            margin="normal"
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
          />
          <TextField
            label="Year"
            type="number"
            fullWidth
            margin="normal"
            value={categoryYear}
            onChange={(e) => setCategoryYear(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddCategory}
          >
            Add Category
          </Button>

          <Typography variant="h5" mt={4}>
            Add Subcategory
          </Typography>
          <FormControl fullWidth margin="normal">
            <InputLabel>Parent Category</InputLabel>
            <Select value={selectedCategoryId} onChange={handleCategoryChange}>
              {mainCategories.map((cat) => (
                <MenuItem key={cat.cat_id} value={cat.cat_id}>
                  {cat.cat_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Subcategory Name"
            fullWidth
            margin="normal"
            value={subcategoryName}
            onChange={(e) => setSubcategoryName(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddSubcategory}
          >
            Add Subcategory
          </Button>
        </Box>
      ) : activeTab === "upload" ? (
        <Box mt={4}>
          <Box mb={3}>
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                <Typography variant="h6">
                  Confirmed Questions Uploaded: {confirmedCount}
                </Typography>

                <Typography variant="h6">
                  If you're getting errors, your cookie might have expired
                  (every 4 hours). Log back out and then sign in again.
                </Typography>
                <TextField
                  label="Target Questions"
                  type="number"
                  value={targetCount}
                  onChange={handleTargetChange}
                  fullWidth
                  margin="normal"
                />
                <Typography variant="body1">
                  Progress: {((confirmedCount / targetCount) * 100).toFixed(2)}%
                </Typography>
              </>
            )}
          </Box>
          <FormControl fullWidth margin="normal">
            <InputLabel>Main Category</InputLabel>
            <Select
              value={selectedCategoryId}
              onChange={(e) => setSelectedCategoryId(e.target.value)}
            >
              {mainCategories.map((category) => (
                <MenuItem key={category.cat_id} value={category.cat_id}>
                  {category.cat_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal" disabled={!selectedCategoryId}>
            <InputLabel>Subcategory</InputLabel>
            <Select
              value={selectedSubcategoryId}
              onChange={(e) => setSelectedSubcategoryId(e.target.value)}
            >
              {(subCategories[selectedCategoryId] || []).map((subcat) => (
                <MenuItem key={subcat.subcat_id} value={subcat.subcat_id}>
                  {subcat.subcategory_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            label="Question Text"
            multiline
            rows={4}
            fullWidth
            margin="normal"
            value={questionText}
            onChange={(e) => setQuestionText(e.target.value)}
          />
          <TextField
            label="Lecture Reference"
            fullWidth
            margin="normal"
            value={lectureReference}
            onChange={(e) => setLectureReference(e.target.value)}
          />

          <Typography variant="h6" gutterBottom>
            Types of Questions:
          </Typography>
          <Typography variant="body2" gutterBottom>
            1 = MCQ
            <br />2 = VSAQ
          </Typography>
          <TextField
            label="Question Type"
            fullWidth
            margin="normal"
            value={questionType}
            onChange={(e) => {
              const value = e.target.value;
              if (value === "1" || value === "2" || value === "") {
                setQuestionType(value);
              } else {
                alert("Only 1 (MCQ) or 2 (VSAQ) are allowed.");
              }
            }}
            helperText="Enter 1 for MCQ or 2 for VSAQ."
          />

          <TextField
            label="Image URL"
            fullWidth
            margin="normal"
            value={image}
            onChange={(e) => setImage(e.target.value)}
            helperText="Enter the URL for the question's image."
          />

          {/* Choices Section */}
          <Typography variant="h6" mt={4}>
            Choices (Separate each choice with a backslash `\`)
          </Typography>
          <TextField
            label="Choices Text"
            multiline
            rows={3}
            fullWidth
            margin="normal"
            value={choicesText}
            onChange={(e) => setChoicesText(e.target.value)}
            helperText="Example: Choice 1 \ Choice 2 \ Choice 3"
          />
          <Typography variant="h6" mt={4}>
            Explanations (Separate each explanation with a backslash `\`)
          </Typography>
          <TextField
            label="Explanations Text"
            multiline
            rows={3}
            fullWidth
            margin="normal"
            value={explanationsText}
            onChange={(e) => setExplanationsText(e.target.value)}
            helperText="Example: Explanation 1 \ Explanation 2 \ Explanation 3"
          />
          <TextField
            label="Correct Choice Index (1-based)"
            type="number"
            fullWidth
            margin="normal"
            value={correctChoiceIndex}
            onChange={(e) => setCorrectChoiceIndex(e.target.value)}
            helperText="Enter the index (1-based) of the correct choice."
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={highYield}
                onChange={(e) => setHighYield(e.target.checked)}
              />
            }
            label="High Yield"
          />
          <Button variant="contained" color="primary" onClick={handleUpload}>
            Upload
          </Button>
        </Box>
      ) : (
        <Box>
          <Box>
            {loadingReviewedQuestions ? (
              <Typography>Loading...</Typography>
            ) : reviewedQuestions.length === 0 ? (
              <Typography>No reviewed questions available.</Typography>
            ) : (
              reviewedQuestions.map((question) => (
                <Box
                  key={question.question_id}
                  mb={4}
                  p={0}
                  sx={{
                    border: "1px solid #e0e0e0",

                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "#f9f9f9",
                  }}
                >
                  <Card>
                    <CardContent>
                      <Typography variant="h6">Feedback History</Typography>
                      {question.feedback_history.map((feedback, index) => (
                        <Box
                          key={index}
                          mb={3}
                          sx={{
                            border: "1px solid #ddd",
                            borderRadius: "2px",
                            padding: "16px",
                            backgroundColor:
                              index % 2 === 0 ? "#f9f9f9" : "#ffffff", // Alternating row colors
                          }}
                        >
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            sx={{ marginBottom: "8px", color: "#333" }}
                          >
                            Version {feedback.feedback_version} (Reviewer ID:{" "}
                            {feedback.reviewer_id})
                          </Typography>
                          <Typography variant="body2">
                            Reviewer's Comment: {feedback.feedback_text}
                          </Typography>
                          <Typography variant="caption">
                            Submitted on:{" "}
                            {new Date(feedback.created_at).toLocaleDateString()}
                          </Typography>
                        </Box>
                      ))}
                      <FormControl fullWidth margin="normal">
                        <InputLabel>Subcategory</InputLabel>
                        <Select
                          value={
                            editedQuestions[question.question_id]?.subcat_id ||
                            ""
                          }
                          onChange={(e) =>
                            handleInputChange(
                              question.question_id,
                              "subcat_id",
                              e.target.value
                            )
                          }
                        >
                          {subcategories.map((subcat) => (
                            <MenuItem
                              key={subcat.subcat_id}
                              value={subcat.subcat_id}
                            >
                              {subcat.subcategory_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {/* Editable Fields */}
                      <TextField
                        label="Question Text"
                        fullWidth
                        margin="normal"
                        value={
                          editedQuestions[question.question_id]
                            ?.question_text || ""
                        }
                        onChange={(e) =>
                          handleInputChange(
                            question.question_id,
                            "question_text",
                            e.target.value
                          )
                        }
                      />
                      <TextField
                        label="Lecture Reference"
                        fullWidth
                        margin="normal"
                        value={
                          editedQuestions[question.question_id]
                            ?.lecture_reference || ""
                        }
                        onChange={(e) =>
                          handleInputChange(
                            question.question_id,
                            "lecture_reference",
                            e.target.value
                          )
                        }
                      />
                      {/* Add other fields (e.g., type, high_yield, image) similarly */}
                      <TextField
                        label="Question Type (1 = MCQ, 2 = VSAQ)"
                        fullWidth
                        margin="normal"
                        value={
                          editedQuestions[question.question_id]?.type || ""
                        }
                        onChange={(e) => {
                          const value = e.target.value;
                          if (["1", "2"].includes(value) || value === "") {
                            handleInputChange(
                              question.question_id,
                              "type",
                              value
                            );
                          } else {
                            alert(
                              "Invalid type. Only 1 (MCQ) or 2 (VSAQ) are allowed."
                            );
                          }
                        }}
                      />

                      <TextField
                        label="Image URL"
                        fullWidth
                        margin="normal"
                        value={
                          editedQuestions[question.question_id]?.image || ""
                        }
                        onChange={(e) =>
                          handleInputChange(
                            question.question_id,
                            "image",
                            e.target.value
                          )
                        }
                      />
                      <TextField
                        label="Correct Choice Index (1-based)"
                        type="number"
                        fullWidth
                        margin="normal"
                        value={
                          editedQuestions[question.question_id]
                            ?.correct_choice_index || ""
                        }
                        onChange={(e) =>
                          handleInputChange(
                            question.question_id,
                            "correct_choice_index",
                            e.target.value
                          )
                        }
                      />

                      <Typography variant="h6" mt={2}>
                        Choices
                      </Typography>
                      {editedQuestions[question.question_id]?.choices.map(
                        (choice, index) => (
                          <Box
                            key={choice.option_id}
                            p={2}
                            sx={{
                              backgroundColor: choice.is_correct
                                ? "#e8f5e9"
                                : "#ffebee",
                              borderRadius: "4px",
                              marginBottom: "8px",
                            }}
                          >
                            <TextField
                              label={`Choice ${index + 1}`}
                              fullWidth
                              margin="normal"
                              value={choice.option_text}
                              onChange={(e) =>
                                handleChoiceChange(
                                  question.question_id,
                                  index,
                                  "option_text",
                                  e.target.value
                                )
                              }
                            />
                            <TextField
                              label="Explanation"
                              fullWidth
                              margin="normal"
                              value={choice.explanation_text}
                              onChange={(e) =>
                                handleChoiceChange(
                                  question.question_id,
                                  index,
                                  "explanation_text",
                                  e.target.value
                                )
                              }
                            />
                          </Box>
                        )
                      )}

                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleSave(question.question_id)}
                      >
                        Save Changes
                      </Button>
                    </CardContent>
                  </Card>
                </Box>
              ))
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default DashboardQuestionUpload;