import React, { useEffect, useState } from "react";
import { getUserDetails, getSubscriptionStatus } from "../../../../api/auth";
import { authenticateUser } from "../../../../redux/slices/authSlice";
import Sidebar from "../DashboardComponents/Sidebar";
import Box from "@mui/material/Box";
import { Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
    const [subscriptionStatus, setSubscriptionStatus] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();



    useEffect(() => {
        async function fetchSubscriptionStatus() {
            try {
                //const subscriptionResponse = await getSubscriptionStatus();
                //const status = subscriptionResponse.status;
                const status = JSON.parse(localStorage.getItem('subscription_status'));
                //setSubscriptionStatus(status);

                // Redirect based on subscription status
                if (status.status === 'active') {
                    console.log('Subscription Status:', status.status);
                    console.log('Redirecting to dashboard...');

                } else {
                    console.log('Subscription Status:', status.status);
                    console.log('Redirecting to payment...');
                    navigate('/dashboard/payment');
                }
            } catch (error) {
                console.error("Error fetching subscription status:", error);
            }
        }

        fetchSubscriptionStatus();
    }, []);



    useEffect(() => {
        async function fetchUserDetails() {
            try {
                const response = await getUserDetails();
                if (response.data.userId) {
                    dispatch(
                        authenticateUser({
                            userId: response.data.userId,
                              role: response.data.role, // Include role here
                              courseName: response.data.courseName,
                              universityName: response.data.universityName,
                              classYear: response.data.classYear,
                              avatar: response.data.avatar, // Include avatar here
                        })
                    );
                }

                // Check subscription status
               

            } catch (error) {
                console.error("Error fetching details or subscription status:", error);
            }
        }

        fetchUserDetails();
    }, [dispatch]);

    return (
        <>
            <Box sx={{ display: "flex" }}>
                <Sidebar />
                <Box component="main" sx={{ flexGrow: 1, p: 0, marginTop: "60px" }}>
                    <Outlet />
                </Box>
            </Box>
        </>
    );
};

export default Dashboard;
