import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
    getUserMockQuestionResults,
    getUserLongFormatScores,
    getMaxPossibleLongFormatScores, // Import the new function
    countType1QuestionsForMock,
} from "../../../../../api/auth";

// MUI Components
import {
    Box,
    Grid,
    Card,
    CardContent,
    Typography,
    CircularProgress,
    Alert,
} from "@mui/material";

import resultsImage from "../../../../../assets/img/results.svg"; // Import the SVG image
import MMxPreclinique from "../../../../../assets/img/MMxPreclinique.svg"; // Import the SVG image

export default function UserMockResults() {
    const [results, setResults] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const userId = useSelector((state) => state.auth.userId);
    const universityName = useSelector((state) => state.auth.universityName); // Get university_name from Redux

    useEffect(() => {
        const fetchResults = async () => {
            try {
                setIsLoading(true);

                // Fetch all required data
                const [mockResults, longFormatScores, maxPossibleScores, type1Count] = await Promise.all([
                    getUserMockQuestionResults(userId),
                    getUserLongFormatScores(userId),
                    getMaxPossibleLongFormatScores(universityName),
                    countType1QuestionsForMock(universityName), // Fetch Type 1 question count with subcategory breakdown
                ]);

                console.log("API Response (Mock Results):", mockResults);
                console.log("API Response (Long Format Scores):", longFormatScores);
                console.log("API Response (Max Possible Scores):", maxPossibleScores);
                console.log("API Response (Type 1 Count):", type1Count);

                if (mockResults.results && mockResults.results.length > 0) {
                    const shortFormatAnswers = mockResults.results[0].short_format_answers;
                    console.log("Short Format Answers:", shortFormatAnswers);

                    const formattedMockResults = Object.entries(shortFormatAnswers).map(
                        ([subcategory, scores]) => ({
                            subcategory,
                            mockCorrectScore: scores.correctScore,
                            mockTotalScore: scores.correctScore + scores.incorrectScore, // Total possible score for short format
                        })
                    );

                    console.log("Formatted Mock Results:", formattedMockResults);

                    const formattedLongFormatScores = Object.entries(
                        longFormatScores.scores || {}
                    ).map(([subcategory, scores]) => ({
                        subcategory,
                        longFormatCorrect: Number(scores.score), // Use scores.score
                    }));

                    console.log("Formatted Long Format Scores:", formattedLongFormatScores);

                    // Create a map of Type 1 question counts by subcategory
                    const type1CountBySubcategory = (type1Count.subcategory_breakdown || []).reduce((acc, item) => {
                        acc[item.subcategory] = item.type1_count;
                        return acc;
                    }, {});

                    console.log("Type 1 Count by Subcategory:", type1CountBySubcategory);

                    const mergedResults = formattedMockResults.map((mockResult) => {
                        const matchingScore = formattedLongFormatScores.find(
                            (score) => score.subcategory === mockResult.subcategory
                        );
                        const maxPossibleScore = maxPossibleScores.max_possible_scores[mockResult.subcategory]?.total_max_marks || 0;
                        const type1CountForSubcategory = type1CountBySubcategory[mockResult.subcategory] || 0;

                        const totalCorrectScore =
                            mockResult.mockCorrectScore +
                            (matchingScore ? matchingScore.longFormatCorrect : 0);
                        const totalPossibleScore =
                            Number(mockResult.mockTotalScore || 0) + Number(maxPossibleScore || 0) + Number(type1CountForSubcategory || 0); // Ensure all values are numbers

                        console.log(`Subcategory: ${mockResult.subcategory}`);
                        console.log(`mockTotalScore: ${mockResult.mockTotalScore}`);
                        console.log(`maxPossibleScore: ${maxPossibleScore}`);
                        console.log(`type1CountForSubcategory: ${type1CountForSubcategory}`);
                        console.log(`totalPossibleScore: ${totalPossibleScore}`);

                        return {
                            subcategory: mockResult.subcategory,
                            totalCorrectScore,
                            percentageScore: totalPossibleScore > 0
                                ? ((totalCorrectScore / totalPossibleScore) * 100).toFixed(2) // Calculate percentage
                                : 0, // Fallback to 0 if totalPossibleScore is 0
                        };
                    });

                    console.log("Merged Results:", mergedResults);

                    // Calculate overall metrics
                    const overallMetrics = mergedResults.reduce(
                        (acc, result) => {
                            acc.totalMarksObtained += Number(result.totalCorrectScore || 0);
                            acc.totalPossibleMarks += Number(result.totalPossibleScore || 0); // Use totalPossibleScore directly
                            return acc;
                        },
                        { totalMarksObtained: 0, totalPossibleMarks: 0 }
                    );

                    console.log("Overall Metrics (Before Adding Type 1 Count):", overallMetrics);

                    // Add total Type 1 question count to total possible marks
                    overallMetrics.totalPossibleMarks += Number(type1Count.total_type1_count) || 0;

                    console.log("Overall Metrics (After Adding Type 1 Count):", overallMetrics);

                    const overallPercentage =
                        overallMetrics.totalPossibleMarks > 0
                            ? ((overallMetrics.totalMarksObtained / overallMetrics.totalPossibleMarks) * 100).toFixed(2)
                            : 0;

                    console.log("Overall Percentage:", overallPercentage);

                    setResults({
                        subcategoryResults: mergedResults,
                        overallMetrics: {
                            ...overallMetrics,
                            overallPercentage,
                        },
                    });
                } else {
                    console.error("No mock results found in response");
                    throw new Error("No mock results found in response");
                }
            } catch (error) {
                console.error("Failed to fetch results:", error);
                setError(
                    "Failed to fetch results. Please click another page, and click back on the Mock component."
                );
            } finally {
                setIsLoading(false);
            }
        };

        fetchResults();
    }, [userId, universityName]);



    if (isLoading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100vh"
            >
                <CircularProgress />
                <Typography sx={{ ml: 2 }}>Loading results...</Typography>
            </Box>
        );
    }

    if (error) {
        return (
            <Box textAlign="center" mt={4}>
                <Alert severity="error">{error}</Alert>
            </Box>
        );
    }

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                p: 3,
                maxWidth: "1200px",
                mx: "auto",
                gap: 4, // Space between results and images
            }}
        >
            {/* Left Side: Mock Results */}
            <Box sx={{ flex: 1 }}>
                <Typography variant="h3" gutterBottom textAlign="center">
                    Your Mock Test Results
                </Typography>

                {/* Overall Metrics Box */}
                {results && (
                    <Card sx={{ mb: 4, p: 2, borderRadius: 2, boxShadow: 3, minHeight: 250 }}>
                        <CardContent>
                            <Typography variant="h5" sx={{ fontWeight: "bold", textAlign: "center" }}>
                                Overall Performance
                            </Typography>
                            <Typography variant="body1" textAlign="center">
                                {results.overallMetrics.overallPercentage}% (
                                {results.overallMetrics.totalMarksObtained} out of {results.overallMetrics.totalPossibleMarks})
                            </Typography>
                        </CardContent>
                    </Card>
                )}

                {/* Subcategory Results */}
                {results && results.subcategoryResults.length > 0 ? (
                    <Grid container spacing={2}>
                        {results.subcategoryResults.map((result, index) => (
                            <Grid item xs={12} sm={6} key={index}>
                                <Card sx={{ p: 2, borderRadius: 2, boxShadow: 3 }}>
                                    <CardContent>
                                        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                                            {result.subcategory}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            <strong>Total Correct Score:</strong>{" "}
                                            {result.totalCorrectScore}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            <strong>Percentage Score:</strong>{" "}
                                            {result.percentageScore}%
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <Typography textAlign="center">No results found.</Typography>
                )}
            </Box>

      {/* Right Side: Image */}
      <Box
        sx={{
          flex: { xs: 0.7, sm: 0.8, md: 1 }, // Shrinks on small screens
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* Text Section */}
        <Typography variant="h4" gutterBottom textAlign="center">
          Brought to you by
        </Typography>

                <img
                    src={MMxPreclinique}
                    alt="Mock Test Results"
                    style={{ maxWidth: "250px", height: "auto" }}
                />

                <Typography variant="h6" gutterBottom textAlign="center">
                    Feedback Session: 21/03/2025 @ 16:00 GMT
                </Typography>

                <img
                    src={resultsImage}
                    alt="Mock Test Results"
                    style={{ maxWidth: "100%", height: "auto" }}
                />
            </Box>
        </Box>
    );
}