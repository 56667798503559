import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
    hasUserSubmittedMock,
    hasUserSubmittedShortFormat,
} from "../../../../api/auth";
import "./DashboardPagesStyles/DashboardMockTest.css";
import AnimatedBlob from "../DashboardComponents/AnimatedBlob";
import MockTestPracticeInterface from "./MockTestFiles/MockTestPracticeInterface";
import { setTraceToZero } from "../../../../redux/reducers/question_reducer";

import MMxPreclinique from "../../../../assets/img/MMxPreclinique.svg";

// Import images for the blobs
import BlobImage1 from "../../../../assets/img/avatar1.svg";
import BlobImage2 from "../../../../assets/img/avatar2.svg";
import BlobImage3 from "../../../../assets/img/avatar3.svg";
import BlobImage4 from "../../../../assets/img/avatar4.svg";
import BlobImage5 from "../../../../assets/img/avatar5.svg";
import BlobImage7 from "../../../../assets/img/avatar8.svg";

const DashboardMockTest = () => {
    const [isCodeValid, setIsCodeValid] = useState(false);
    const [codeInput, setCodeInput] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [showPracticeQuestionInterface, setShowPracticeQuestionInterface] =
        useState(false);
    const [practiceCompleted, setPracticeCompleted] = useState(false);
    const userId = useSelector((state) => state.auth.userId);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const validCode = "999999";

    useEffect(() => {
        const checkSubmissionStatus = async () => {
            try {
                const hasSubmitted = await hasUserSubmittedMock(userId);
                const hasSubmittedShortFormat = await hasUserSubmittedShortFormat(
                    userId
                );

                if (hasSubmittedShortFormat.results.length > 0) {
                    const shortFormatAnswers =
                        hasSubmittedShortFormat.results[0].short_format_answers;
                    const hasScores = Object.values(shortFormatAnswers).some(
                        (subcat) => subcat.correctScore > 0 || subcat.incorrectScore > 0
                    );

                    if (hasScores) {
                        navigate("/dashboard/mock-test/questions/results", {
                            replace: true,
                        });
                    }
                }
            } catch (error) {
                console.error("Error checking submission status:", error);
            }
        };

        checkSubmissionStatus();
    }, [userId, navigate]);

    const handleCodeInputChange = (e) => {
        setCodeInput(e.target.value);
    };

    const handleCodeSubmit = () => {
        if (codeInput === validCode) {
            setIsCodeValid(true);
            setShowPracticeQuestionInterface(true);
            setErrorMessage("");
        } else {
            setErrorMessage("Invalid code. Please try again.");
        }
    };

    const handlePracticeComplete = () => {
        setPracticeCompleted(true);
    };

    const handleStartExam = () => {
        dispatch(setTraceToZero());
        navigate("/dashboard/question-bank/mocktestquestions");
    };

    return (
        <div className="dashboard-mock-test">
            <AnimatedBlob image={BlobImage1} className="blob-1" />
            <AnimatedBlob image={BlobImage2} className="blob-2" />
            <AnimatedBlob image={BlobImage3} className="blob-3" />
            <AnimatedBlob image={BlobImage4} className="blob-4" />
            <AnimatedBlob image={BlobImage5} className="blob-5" />
            <AnimatedBlob image={BlobImage7} className="blob-7" />

            {!isCodeValid ? (
                <div className="paper">
                    <img
                        src={MMxPreclinique}
                        alt="MMxPreclinique Logo"
                        className="mmx-logo"
                    />
                    <h1 className="mock-test-title">Mock Test</h1>
                    <div className="overlay">
                        <div className="overlay-content">
                            <h2>Enter the code to start the mock exam</h2>
                            <input
                                type="text"
                                value={codeInput}
                                onChange={handleCodeInputChange}
                                placeholder="Enter code"
                                className="code-input"
                            />
                            <button onClick={handleCodeSubmit} className="submit-button">
                                Start Mock Exam
                            </button>
                            {errorMessage && <p className="error-message">{errorMessage}</p>}
                        </div>
                    </div>
                </div>
            ) : showPracticeQuestionInterface && !practiceCompleted ? (
                <div className="practice-container">
                    <MockTestPracticeInterface onComplete={handlePracticeComplete} />
                </div>
            ) : (
                <div className="paper paper-wide">
                    <h1 className="exam-title">Welcome to the Mock Exam</h1>
                    <p className="exam-subtitle">Follow the rules before starting.</p>

                    <div className="instructions-container">
                        <h2 className="instructions-title">Instructions:</h2>
                        <ul className="instructions-list">
                            <li>Do not refresh the page.</li>
                            <li>Do not navigate to another page or component.</li>
                            <li>
                                Once an answer is submitted for MCQs, it cannot be changed. For VSAQs and SAQs, you can modify your answers until final submission.
                            </li>
                            <li>
                                If unsure of an answer, flag the question and return to it later.
                            </li>
                            <li>
                                On the final page, click the <strong>"Results & Review"</strong> button to complete the test. You cannot go back after clicking this button.
                            </li>
                            <li>
                                Self-mark VSAQs and SAQs honestly. You can complete this at your own pace, but results will only be available after self-marking.
                            </li>
                            <li>
                                For VSAQs and SAQs, ensure you click <strong>"Confirm Answer Here Before Submit"</strong> followed by <strong>"Submit"</strong>.
                            </li>
                            <li>
                                The timer is a guide. When it reaches zero, the test will not auto-submit.
                            </li>
                        </ul>
                    </div>

                    <button onClick={handleStartExam} className="start-exam-button">
                        Start Exam
                    </button>
                </div>
            )}
        </div>
    );
};

export default DashboardMockTest;