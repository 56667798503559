import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const UploaderRoutes = () => {
  const { isAuth, role } = useSelector((state) => state.auth);

  if (!isAuth) {
    return <Navigate to="/login" />;
  }

  if (role !== "uploader" && role !== "reviewer") {
    // Only "uploader" or "reviewer" roles are allowed
    return <Navigate to="/dashboard/homepage" />;
  }

  return <Outlet />;
};

export default UploaderRoutes;