import React, { useEffect, useState, useRef } from "react";
import {
  fetchProtectedInfo,
  onLogout,
  getUserProgress,
  updateTimestamp,
  reviewFlaggedQuestions,
  howManyQuestionsAreThereTotal,
  howManyQuestionsAttempted,
  howManyQuestionsCorrect,
  howManyQuestionsIncorrect,
    resumeSession,
    getSubscriptionStatus,
    getPaidStatus
} from "../../../../api/auth";
import { useDispatch, useSelector } from "react-redux";
import { unauthenticateUser } from "../../../../redux/slices/authSlice";
import { setSelectedSubcategories } from "../../../../redux/slices/selectedSubcategoriesSlice";
import { setQuestions } from "../../../../redux/slices/selectedQuestionsSlice";
import {
  setTraceToZero,
  setTraceAction,
  setQuestionGetFromResumeToTrue,
} from "../../../../redux/reducers/question_reducer";
import { useFetchQuestionsByIds } from "../../../../hooks/FetchQuestion"; //start question for resume
import { ToastContainer, toast } from "react-toastify";
import "./DashboardPagesStyles/DashboardHomePage.css";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Skeleton from "@mui/material/Skeleton";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { PieChart, Pie, Cell, Legend, Tooltip } from "recharts";
import { useNavigate } from "react-router-dom";
import FlaggedQuestionsIcon from "../../../../assets/img/flaggedQuestions.svg";
import HangingOwl from "../../../../assets/img/hangingOwl.svg";
import Lottie from "lottie-web";
import myLottieAnimation1 from "../../../../assets/lottieAnimations/squigglyLine.json";

import ChangeAvatar from "../DashboardComponents/ChangeAvatar";

const FriendSkeleton = () => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
      <Skeleton variant="circular" width={40} height={40} />
      <Skeleton variant="rectangular" width={140} height={20} mx={2} />
      <Skeleton variant="circular" width={20} height={20} mr={1} />
      <Skeleton variant="circular" width={20} height={20} ml={1} />
    </Box>
  );
};

const DashboardHomePage = () => {
  const animationRef = useRef(null);
  const [animationKey, setAnimationKey] = useState(Date.now()); // Unique key for each mount
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [protectedData, setProtectedData] = useState(null);
  const [username, setUsername] = useState(null);
  const [userProgress, setUserProgress] = useState(null);
  const userId = useSelector((state) => state.auth.userId);
  const [elapsedTime, setElapsedTime] = useState("");
  const university_name = useSelector((state) => state.auth.universityName);
  const course = useSelector((state) => state.auth.courseName);
  const [totalQuestions, setTotalQuestions] = useState(100);
  const [attemptedQuestions, setAttemptedQuestions] = useState(10);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [incorrectAnswers, setIncorrectAnswers] = useState(0);
    const [flaggedQuestions, setFlaggedQuestions] = useState([]);
    const [subscriptionStatus, setSubscriptionStatus] = useState("");



    useEffect(() => {
        async function fetchSubscriptionStatus() {
            try {
                //const subscriptionResponse = await getSubscriptionStatus();
                const status = JSON.parse(localStorage.getItem('subscription_status'));
                //const status = subscriptionResponse.status;
                //setSubscriptionStatus(status);

                //const substatus = await getPaidStatus();
                //const REALSUBSTATUS = substatus.data;
                //console.log("AAAA", REALSUBSTATUS);

                // Redirect based on subscription status
                if (status.status === 'active') {
                    console.log('Subscription Status:', status.status);
                    console.log('Redirecting to dashboard...');

                } else {
                    console.log('Subscription Status:', status.status);
                    console.log('Redirecting to payment...');
                    navigate('/dashboard/payment');
                }
            } catch (error) {
                console.error("Error fetching subscription status:", error);
            }
        }

        fetchSubscriptionStatus();
    }, []);



  useEffect(() => {
    const instance = Lottie.loadAnimation({
      container: animationRef.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: myLottieAnimation1,
    });

    instance.setSpeed(0.5);

    return () => instance.destroy();
  }, [animationKey]); // Dependency on animationKey to reload animation on key change

  useEffect(() => {
    // Reset the animation key every time the component mounts
    setAnimationKey(Date.now());
  }, []);

  const timeSinceLastInteraction = (timestamp) => {
    const now = new Date();
    const lastInteractionDate = new Date(timestamp);
    const differenceInSeconds = Math.floor((now - lastInteractionDate) / 1000);

    const hours = Math.floor(differenceInSeconds / 3600);
    const minutes = Math.floor((differenceInSeconds % 3600) / 60);
    const seconds = differenceInSeconds % 60;

    if (hours > 0) {
      return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
    } else {
      return `${seconds} second${seconds !== 1 ? "s" : ""} ago`;
    }
  };

  useEffect(() => {
    if (userProgress && userProgress.timestamp) {
      setElapsedTime(timeSinceLastInteraction(userProgress.timestamp));

      const interval = setInterval(() => {
        setElapsedTime(timeSinceLastInteraction(userProgress.timestamp));
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [userProgress]);

  useEffect(() => {
    async function fetchUserProgress() {
      if (!userId) return;

      console.log(userId);
      try {
        const response = await getUserProgress(userId);
        console.log("User Progress Response:", response.data);
        if (response.data.success) {
          setUserProgress(response.data.data);
          console.log("success in fetchthing data");
        }
      } catch (error) {
        console.error("Error fetching user progress:", error);
      }
    }

    fetchUserProgress();
  }, [userId]);

  useEffect(() => {
    async function getFlaggedQuestions() {
      try {
        const response = await reviewFlaggedQuestions(userId);
        console.log("Flagged Questions Response:", response.data);
        if (response.data.success) {
          setFlaggedQuestions(response.data.flaggedQuestions);
        } else {
          console.error(
            "Error fetching flagged questions:",
            response.data.error
          );
        }
      } catch (error) {
        console.error("Error calling reviewFlaggedQuestions API:", error);
      }
    }

    if (userId) {
      console.log("succesful");
      getFlaggedQuestions();
    }
  }, [userId]);

  useEffect(() => {
    if (userId && course && university_name) {
      // Fetch data related to userId here
      const fetchData = async () => {
        try {
          const correctResponse = await howManyQuestionsCorrect(userId);
          const attemptedResponse = await howManyQuestionsAttempted(userId);
          const totalResponse = await howManyQuestionsAreThereTotal(
            course,
            university_name
          );
          const incorrectResponse = await howManyQuestionsIncorrect(userId);

          // Update state with fetched data
          setCorrectAnswers(parseInt(correctResponse.data.questions[0].count));
          setAttemptedQuestions(
            parseInt(attemptedResponse.data.questions[0].count)
          );
          setTotalQuestions(parseInt(totalResponse.data.questions[0].count));
          setIncorrectAnswers(
            parseInt(incorrectResponse.data.questions[0].count)
          );
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchData(); // Call the function to fetch data
    }
  }, [userId, course, university_name]); // Include course and university_name as dependencies

  /*    async function resumeSession() {
        if (!userProgress || !userProgress.subcat_ids) {
            console.error("No user progress found to resume.");
            return;
        }

        dispatch(setSelectedSubcategories(userProgress.subcat_ids));
        dispatch(setTraceToZero());

        await updateTimestamp(userId);

        navigate("/dashboard/question-bank/questions");
    }
    */
  async function handleResumeSession() {
    try {
      console.log("userid", userId);
      const response = await resumeSession(userId);
      console.log("Response:", response);

      if (
        response.data &&
        response.data.session &&
        response.data.session.length > 0
      ) {
        const sessionArray = response.data.session[0].sessionarray;
        const questionNumber = response.data.session[0].questionnumber;

        // Call useFetchQuestionByIds with sessionArray
        dispatch(setQuestions(sessionArray));
        dispatch(setTraceAction(questionNumber));
        dispatch(setQuestionGetFromResumeToTrue());

        navigate("/dashboard/question-bank/questionsById");
        //await updateTimestamp(userId);

        // navigate("/dashboard/question-bank/questions");
      } else {
        console.error("No session data found");
      }
    } catch (error) {
      console.error("Error resuming session:", error.message);
    }
  }

  const logout = async () => {
    try {
      await onLogout();

      dispatch(unauthenticateUser());
      localStorage.removeItem("isAuth");

      toast.success("🦉 Logged out successfully!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } catch (error) {
      console.log(error.response);
      toast.error("Encountered an error logging you out!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const protectedInfo = async () => {
    try {
      const { data } = await fetchProtectedInfo();

      setProtectedData(data.info);
      setUsername(data.username);

      setLoading(false);
    } catch (error) {
      logout();
    }
  };

  useEffect(() => {
    protectedInfo();
  }, []);

  if (loading) {
    return <h1>Loading...</h1>;
  }

  const data = [
    { name: "Correct", value: correctAnswers },
    { name: "Incorrect", value: incorrectAnswers },
  ];

  const dataAttemptedVsRemaining = [
    { name: "Attempted", value: attemptedQuestions },
    { name: "Remaining", value: totalQuestions - attemptedQuestions },
  ];

  const COLORS = ["#4CAF50", "#FF5733"];

  const COLORS2 = ["#FFC107", "#3F51B5"];

  return (
    <>
      <Box
        component="main"
        sx={{
          backgroundColor: "rgb(249, 249, 249)",
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <div className="dashboard-header">
          <div className="dashboadWelcomePage">
            <h1 className="title">Welcome back, {username}! </h1>
          </div>
          <img src={HangingOwl} alt="Hanging Owl" className="hanging-owl" />
        </div>

        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={4.5}>
              <Paper
                sx={{
                  padding: "42px 48px",
                  marginBottom: 2,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: 140,
                }}
              >
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  className="headingStyle"
                >
                  Jump Back Into Practice!
                </Typography>
                {/* <Typography variant="h5" gutterBottom className="headingStyle">
                  Principle of Medicine; Molecular Biology of the Cell, Genetics
                </Typography> */}
                <Box sx={{ alignSelf: "flex-start" }}>
                  {userProgress ? (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleResumeSession}
                        sx={{
                          textTransform: "none",
                          maxWidth: "200px",
                          width: "fit-content",
                          fontWeight: "bold",
                          fontSize: "1rem",
                          marginBottom: "8px",
                        }}
                      >
                        Resume Session
                      </Button>
                      {elapsedTime && (
                        <Typography variant="body2">
                          Last interacted: {elapsedTime}
                        </Typography>
                      )}
                    </>
                  ) : (
                    <Typography
                      variant="body1"
                      sx={{ marginTop: "8px", fontStyle: "italic" }}
                    >
                      No session found
                    </Typography>
                  )}
                </Box>
              </Paper>
              <Paper
                sx={{
                  padding: "42px 48px",
                  marginBottom: 2,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: 440,
                }}
              >
                <Typography variant="h5" gutterBottom className="headingStyle">
                  Attempted Questions
                </Typography>
                <PieChart
                  width={
                    window.innerWidth < 600 ? window.innerWidth - 100 : 400
                  } // Responsive width
                  height={340}
                  style={{ alignSelf: "center" }}
                >
                  <Pie
                    data={dataAttemptedVsRemaining}
                    cx="50%"
                    cy="50%"
                    outerRadius={120}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {dataAttemptedVsRemaining.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS2[index % COLORS2.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </Paper>
            </Grid>
            <Grid item xs={12} md={8} lg={4.5}>
              <Paper
                sx={{
                  padding: "42px 48px",
                  marginBottom: 2,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start", // Align content to the top
                  alignItems: "center",
                  height: 596,
                }}
              >
                <Typography
                  variant="h5"
                  gutterBottom
                  className="headingStyle"
                  sx={{ textAlign: "center", width: "100%" }} // Ensure heading spans the full width
                >
                  Correct Questions
                </Typography>

                {correctAnswers > 0 || incorrectAnswers > 0 ? (
                  <PieChart
                    width={450} // Increased width
                    height={300} // Increased height
                    style={{ alignSelf: "center", marginTop: "60px" }} // Add margin-top to space out from heading
                  >
                    <Pie
                      data={data}
                      cx="50%"
                      cy="50%"
                      outerRadius={120} // Adjust outerRadius for larger PieChart
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {data.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                  </PieChart>
                ) : (
                  <Typography
                    variant="body1"
                    sx={{ marginTop: "20px", textAlign: "center" }}
                  >
                    No questions attempt have been recorded, visit the question
                    bank to get started!
                  </Typography>
                )}
              </Paper>
            </Grid>
           <Grid item xs={12} md={4} lg={3}>
<Paper
sx={{
padding: "42px 48px",
display: "flex",
flexDirection: "column",
height: 596, //736
marginBottom: 2,
}}
>
<ChangeAvatar />
</Paper>
</Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  height: 480, // Adjust height as needed
                  marginBottom: 2,
                }}
              >
                <Typography variant="h5" gutterBottom className="headingStyle">
                  Flagged Questions
                </Typography>
                <Typography gutterBottom>(coming soon....)</Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <img
                    src={FlaggedQuestionsIcon}
                    alt="Flagged Questions"
                    style={{ width: "300px", height: "300px" }}
                  />
                </div>

                {flaggedQuestions.map((question, index) => (
                  <div key={index}>
                    <p>Question ID: {question.question_id}</p>
                    <p>
                      Timestamp: {new Date(question.timestamp).toLocaleString()}
                    </p>
                    <hr />
                  </div>
                ))}
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default DashboardHomePage;

//   return (
//     <>
//       <Box
//         component="main"
//         sx={{
//           backgroundColor: "rgb(249, 249, 249)",
//           flexGrow: 1,
//           height: "100vh",
//           overflow: "auto",
//         }}
//       >
//         <div className="dashboadWelcomePage">
//           <h1 className="title">Welcome back, {username}! 👋</h1>
//         </div>

//         <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
//           <Grid container spacing={2}>
//             <Grid item xs={12} md={12} lg={4.5}>
//               <Paper
//                 sx={{
//                   padding: "42px 48px",
//                   marginBottom: 2,
//                   display: "flex",
//                   flexDirection: "column",
//                   justifyContent: "center",
//                   height: 240,
//                 }}
//               >
//                 <Typography variant="h6" component="h2" gutterBottom>
//                   Carry on with questions...?
//                 </Typography>
//                 <Typography variant="h5" gutterBottom className="headingStyle">
//                   Principle of Medicine; Molecular Biology of the Cell, Genetics
//                 </Typography>
//                 <Box sx={{ alignSelf: "flex-start" }}>
//                   {userProgress ? (
//                     <Button
//                       variant="contained"
//                       color="primary"
//                       onClick={handleResumeSession}
//                       sx={{
//                         textTransform: "none",
//                         maxWidth: "200px",
//                         width: "fit-content",
//                         fontWeight: "bold",
//                         fontSize: "1rem",
//                         marginBottom: "8px",
//                       }}
//                     >
//                       Resume Session
//                     </Button>
//                   ) : null}
//                   {elapsedTime && (
//                     <Typography variant="body2">
//                       Last interacted: {elapsedTime}
//                     </Typography>
//                   )}
//                 </Box>
//               </Paper>
//               <Paper
//                 sx={{
//                   padding: "42px 48px",
//                   marginBottom: 2,
//                   display: "flex",
//                   flexDirection: "column",
//                   justifyContent: "center",
//                   height: 240,
//                 }}
//               >
//                 <PieChart width={400} height={240}>
//                   <Pie
//                     data={dataAttemptedVsRemaining}
//                     cx="50%"
//                     cy="50%"
//                     outerRadius={80}
//                     fill="#8884d8"
//                     dataKey="value"
//                   >
//                     {dataAttemptedVsRemaining.map((entry, index) => (
//                       <Cell
//                         key={`cell-${index}`}
//                         fill={COLORS2[index % COLORS2.length]}
//                       />
//                     ))}
//                   </Pie>
//                   <Tooltip />
//                   <Legend />
//                 </PieChart>
//               </Paper>
//             </Grid>

//             <Grid spacing={2} item xs={12} md={8} lg={4.5}>
//               <Paper
//                 sx={{
//                   padding: "42px 48px",
//                   marginBottom: 2,
//                   display: "flex",
//                   flexDirection: "column",
//                   justifyContent: "center",
//                   height: 496,
//                 }}
//               >
//                 <PieChart width={400} height={240}>
//                   <Pie
//                     data={data}
//                     cx="50%"
//                     cy="50%"
//                     outerRadius={80}
//                     fill="#8884d8"
//                     dataKey="value"
//                   >
//                     {data.map((entry, index) => (
//                       <Cell
//                         key={`cell-${index}`}
//                         fill={COLORS[index % COLORS.length]}
//                       />
//                     ))}
//                   </Pie>
//                   <Tooltip />
//                   <Legend />
//                 </PieChart>
//               </Paper>
//             </Grid>

//             <Grid item xs={12} md={4} lg={3}>
//               <Paper
//                 sx={{
//                   p: 2,
//                   display: "flex",
//                   flexDirection: "column",
//                   height: 496, //736
//                   marginBottom: 2,
//                 }}
//               >
//                 <Typography variant="h6" gutterBottom>
//                   Friends list
//                   <Typography gutterBottom>(coming soon....)</Typography>
//                 </Typography>

//                 <Box sx={{ mb: 2 }}>
//                   <Typography variant="body2" gutterBottom>
//                     Search
//                   </Typography>
//                   <Skeleton variant="rectangular" width="100%" height={20} />
//                 </Box>

//                 <Box sx={{ overflowY: "auto" }}>
//                   <FriendSkeleton />
//                   <FriendSkeleton />
//                   <FriendSkeleton />
//                   <FriendSkeleton />
//                   <FriendSkeleton />
//                   <FriendSkeleton />
//                   <FriendSkeleton />
//                   <FriendSkeleton />
//                   <FriendSkeleton />
//                   <FriendSkeleton />
//                   <FriendSkeleton />
//                   <FriendSkeleton />
//                 </Box>
//               </Paper>
//             </Grid>

//             <Grid item xs={12} md={12} lg={12}>
//               <Paper
//                 sx={{
//                   p: 2,
//                   display: "flex",
//                   flexDirection: "column",
//                   height: 480,
//                 }}
//               >
//                 <Typography variant="h5" gutterBottom className="headingStyle">
//                   Flagged Questions
//                 </Typography>
//                 <Typography gutterBottom>(coming soon....)</Typography>
//                 {flaggedQuestions.map((question, index) => (
//                   <div key={index}>
//                     <p>Question ID: {question.question_id}</p>
//                     <p>
//                       Timestamp: {new Date(question.timestamp).toLocaleString()}
//                     </p>
//                     <hr />
//                   </div>
//                 ))}
//               </Paper>
//             </Grid>
//           </Grid>
//         </Container>
//       </Box>
//     </>
//   );
// };

// export default DashboardHomePage;
