import { createSlice } from "@reduxjs/toolkit";

export const questionReducer = createSlice({
    name: "questions",
    initialState: {
        queue: [], // This will store an array of question objects.
        answers: [],
        trace: 0,
        isSubmitted: [],
        isCorrect: [],
        type: 1,
        gettingResultFromResume: false,
        currentSubcategory: null, // Add currentSubcategory to the state.
        userAnswer: null,
        childQuestions: null,
        max_marks: 1,
        subQuestionsData: [], // Add subQuestionsData to the state
    },
    reducers: {
        startExamAction: (state, action) => {
            const { question, answers } = action.payload; // Directly take the questions array from the payload

            // Get the current question based on the trace value
            const currentQuestion = question[state.trace];
            

            return {
                ...state,
                queue: question,
                answers: answers,
                type: currentQuestion?.type || 1, // Use current question's type
                currentSubcategory: currentQuestion?.subcategory || "", // Use current question's subcategory
                childQuestions: currentQuestion?.childQuestions || "",
                
            };
        },
        moveNextAction: (state) => {
            const nextTrace = state.trace + 1;
            if (nextTrace < state.queue.length) {
                const nextQuestion = state.queue[nextTrace];
                return {
                    ...state,
                    trace: nextTrace,
                    type: nextQuestion?.type || 1, // Update type based on the next question
                    currentSubcategory: nextQuestion?.subcategory || "", // Update subcategory
                    
                };
            }
            return state;
        },
        movePrevAction: (state) => {
            const prevTrace = state.trace - 1;
            if (prevTrace >= 0) {
                const prevQuestion = state.queue[prevTrace];
                return {
                    ...state,
                    trace: prevTrace,
                    type: prevQuestion?.type || 1, // Update type based on the previous question
                    currentSubcategory: prevQuestion?.subcategory || "", // Update subcategory
                    
                };
            }
            return state;
        },
        resetAllAction: () => {
            return {
                queue: [],
                trace: 0,
                currentSubcategory: "", // Reset the subcategory as well
            };
        },
        setIsSubmitted: (state, action) => {
            const { index, value } = action.payload;
            state.isSubmitted[index] = value;
        },
        setIsCorrect: (state, action) => {
            const { index, value } = action.payload;
            state.isCorrect[index] = value;
        },
        setTraceAction: (state, action) => {
            const newTrace = action.payload;
            if (newTrace >= 0 && newTrace < state.queue.length) {
                const currentQuestion = state.queue[newTrace];
                return {
                    ...state,
                    trace: newTrace,
                    type: currentQuestion?.type || 1, // Update type based on the new trace
                    currentSubcategory: currentQuestion?.subcategory || "", // Update subcategory
                };
            }
            return state;
        },
        setTraceToZero: (state) => {
            const currentQuestion = state.queue[0];
            return {
                ...state,
                trace: 0,
                type: currentQuestion?.type || 1, // Update type based on the first question
                currentSubcategory: currentQuestion?.subcategory || "", // Update subcategory
            };
        },
        setQuestionGetFromResumeToFalse: (state) => {
            state.gettingResultFromResume = false;
        },
        setQuestionGetFromResumeToTrue: (state) => {
            state.gettingResultFromResume = true;
        },
        setType: (state, action) => { // Add a new reducer to set the 'type' value
            state.type = action.payload;
        },
        // Add a new reducer to set the 'currentSubcategory'
        setCurrentSubcategory: (state, action) => {
            state.currentSubcategory = action.payload;
        },
        setUserAnswer: (state, action) => {
            state.userAnswer = action.payload;
        },
        setMaxMarks: (state, action) => {
            state.max_marks = action.payload; // Add this reducer
        },
        // Add a new reducer to update subQuestionsData
        setSubQuestionsData: (state, action) => {
            state.subQuestionsData = action.payload;
        },
        // Add a new reducer to append to subQuestionsData
        addSubQuestionData: (state, action) => {
            state.subQuestionsData.push(action.payload);
        },
        clearSubQuestionData: (state) => {
            state.subQuestionsData = [];
        },
        clearLongQuestion: (state) => {
            state.userAnswer = null;
        },
    },
});

export const {
    startExamAction,
    moveNextAction,
    movePrevAction,
    resetAllAction,
    setIsSubmitted,
    setIsCorrect,
    setTraceAction,
    setTraceToZero,
    setType,
    setQuestionGetFromResumeToFalse,
    setQuestionGetFromResumeToTrue,
    setCurrentSubcategory, // Export the new action
    setUserAnswer,
    setMaxMarks,
    setSubQuestionsData, // Export the new action
    addSubQuestionData, // Export the new action
    clearSubQuestionData,
    clearLongQuestion,
} = questionReducer.actions;

export default questionReducer.reducer;