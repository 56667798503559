import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    useFetchQuestionBySubcatIds,
    useFetchQuestionsByIds,
} from "../../../../../../hooks/FetchQuestion";
import { getQuestionsToExclude, getQuestionImageLink } from "../../../../../../api/auth";

import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import "../DashboardQuestionInterfaceStyles/DashboardQuestions.css";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

export default function DashboardQuestions({
    onChecked,
    isSubmitted,
    isCorrect,
    getCorrectOptionId,
    onQuestionLoaded,//function prop
}) {
    const dispatch = useDispatch();
    const [showCheckboxes, setShowCheckboxes] = useState(false);
    const [textboxValue, setTextboxValue] = useState("");
    const [showAnswer, setShowAnswer] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");
    const [revealButtonVisible, setRevealButtonVisible] = useState(true);
    const [excludedQuestions, setExcludedQuestions] = useState([]);
    const [image, setImage] = useState(null); // Added from File 1

    const userId = useSelector((state) => state.auth.userId);
    const type = useSelector((state) => state.type.value);
    const questionExclusions = useSelector((state) => state.type.questionExclusions);
    const excludedIds = questionExclusions.length > 0 ? questionExclusions : [0];
    const selectedSubcategories = useSelector((state) => state.selectedSubcategories);

    const [checkedAnswers, setCheckedAnswers] = useState([]);
    const { trace } = useSelector((state) => state.questions);
    const result = useSelector((state) => state.result.result);
    const didIComeFromResume = useSelector((state) => state.questions.gettingResultFromResume);

    const [{ isLoading, apiData, serverError }] = useFetchQuestionBySubcatIds(
        selectedSubcategories,
        type,
        excludedIds
    );

    const questionObj = useSelector((state) => state.questions.queue[state.questions.trace]);

    // Call the passed function when questionObj is available
    useEffect(() => {
        if (typeof onQuestionLoaded === "function" && questionObj) {
            onQuestionLoaded(questionObj);
        }
    }, [questionObj, onQuestionLoaded]);

    // Added from File 1: fetch image based on question ID
    useEffect(() => {
        const fetchImage = async () => {
            if (questionObj && questionObj.id) {
                const imageLink = await getQuestionImageLink(questionObj.id);
                setImage(imageLink.data.session[0].image);
            }
        };
        fetchImage();
    }, [questionObj]);

    function onSelect(optionId, index) {
        onChecked(questionObj.id, optionId);
        let newCheckedAnswers = [...checkedAnswers];
        newCheckedAnswers[trace] = index;
        setCheckedAnswers(newCheckedAnswers);
    }

    const handleRevealButtonClick = () => {
        setShowCheckboxes(true);
        setShowAnswer(true);
        setRevealButtonVisible(false);
    };

    const handleOptionChange = (e) => {
        const selectedValue = e.target.value;
        let selectedOptionId;
        if (selectedValue === "correct") {
            const correctOption = questionObj.options.find((option) => option.is_correct);
            selectedOptionId = correctOption ? correctOption.option_id : null;
        } else if (selectedValue === "incorrect") {
            selectedOptionId = "9999";
        }
        setSelectedOption(selectedValue);
        onChecked(questionObj.id, selectedOptionId);
    };

    useEffect(() => {
        setRevealButtonVisible(true);
        setShowCheckboxes(false);
        setShowAnswer(false);
        setTextboxValue("");
        setSelectedOption("");
    }, [trace]);

    function renderControl(i, option) {
        if (isSubmitted[trace]) {
            if (checkedAnswers[trace] === i) {
                return isCorrect[trace] ? (
                    <div style={{ padding: "8px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <CheckCircleOutlineIcon color="primary" />
                    </div>
                ) : (
                    <div style={{ padding: "8px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <HighlightOffIcon color="error" />
                    </div>
                );
            }
            if (getCorrectOptionId(questionObj.id) === option.option_id) {
                return (
                    <div style={{ padding: "8px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <CheckCircleOutlineIcon color="primary" />
                    </div>
                );
            }
        }
        return (
            <>
                <Radio
                    checked={checkedAnswers[trace] === i}
                    onChange={() => onSelect(option.option_id, i)}
                    name="options"
                    color="primary"
                    disabled={isSubmitted[trace]}
                />
            </>
        );
    }

    function getOptionClass(i, option) {
        if (isSubmitted[trace]) {
            if (checkedAnswers[trace] === i) {
                return isCorrect[trace] ? "correct-answer" : "incorrect-answer";
            }
            if (getCorrectOptionId(questionObj.id) === option.option_id) {
                return "correct-answer";
            }
        }
        return "";
    }

    if (isLoading) return <h3 className="text-light">Loading...</h3>;
    if (serverError) return <h3 className="text-light">{serverError || "Unknown Error"}</h3>;
    if (!questionObj) return <div>Loading...</div>;
    console.log("image link is here,",image);

    return (
    <div className="questions">
        {questionObj.type === 1 && (
            <>
                <h2 className="question-text">{questionObj?.question}</h2>
                {image ? <img src={image} alt="Question Related" style={{ maxWidth: "100%", height: "auto" }} /> : null}
                <FormGroup role="radiogroup">
                    {questionObj?.options
                        .filter((option) => option.option_id !== 9999) // Filter out option_id 9999
                        .map((option, i) => (
                            <div key={option.option_id} className={`option-border-style ${getOptionClass(i, option)}`}>
                                <div style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
                                    <FormControlLabel
                                        value={option.option_id}
                                        control={renderControl(i, option)}
                                        label={option.option_text}
                                        style={{
                                            width: "100%",
                                            marginRight: "0px",
                                            marginLeft: "0px",
                                            paddingRight: "72px",
                                            boxSizing: "border-box",
                                            paddingTop: "2px",
                                            cursor: "pointer",
                                        }}
                                    />
                                </div>
                                <Collapse in={isSubmitted[trace]} timeout="auto" unmountOnExit>
                                    <div className="explanation-style">{option?.explanation_text}</div>
                                </Collapse>
                            </div>
                        ))}
                </FormGroup>
            </>
        )}

        {questionObj.type === 2 && (
            <div>
                <h2 className="question-text">{questionObj?.question}</h2>
                {image ? <img src={image} alt="Question Related" style={{ maxWidth: "100%", height: "auto" }} /> : null}
                <div>
                    <input
                        type="text"
                        placeholder="Enter your answer"
                        className="fancy-text-input"
                        value={textboxValue}
                        onChange={(e) => setTextboxValue(e.target.value)}
                    />
                    {revealButtonVisible && (
                        <button className="reveal-button" onClick={handleRevealButtonClick}>
                            Reveal Answer
                        </button>
                    )}
                    {showCheckboxes && (
                        <div className="checkbox-container">
                            <div>
                                <input
                                    type="radio"
                                    id="correct"
                                    name="answer"
                                    value="correct"
                                    checked={selectedOption === "correct"}
                                    onChange={handleOptionChange}
                                    className="checkbox-input"
                                />
                                <label htmlFor="correct" className="checkbox-label">
                                    Correct
                                </label>
                            </div>
                            <div>
                                <input
                                    type="radio"
                                    id="incorrect"
                                    name="answer"
                                    value="incorrect"
                                    checked={selectedOption === "incorrect"}
                                    onChange={handleOptionChange}
                                    className="checkbox-input"
                                />
                                <label htmlFor="incorrect" className="checkbox-label">
                                    Incorrect
                                </label>
                            </div>
                        </div>
                    )}
                </div>
                {showAnswer && (
                    <div>
                        Correct Answer:
                        {questionObj.options
                            .filter((option) => option.option_id !== 9999) // Filter here as well
                            .map(
                                (option) => option.is_correct && <div key={option.option_id}>{option.option_text}</div>
                            )}
                    </div>
                )}
            </div>
        )}

        {isSubmitted[trace] && questionObj.lectureReference && (
            <div className="lecture-reference">
                <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{ fontStyle: "italic" }}
                >
                    Refer to: {questionObj.lectureReference}
                </Typography>
            </div>
        )}
    </div>
);
}
