
import { ReactComponent as Logo } from "../../../assets/img/realOwlLogo.svg";
import "./LogIn.css"; // Importing the CSS
import React, { useState } from "react";
import { fetchProtectedInfo, onLogin } from "../../../api/auth";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { authenticateUser } from "../../../redux/slices/authSlice";
import { ToastContainer, toast } from "react-toastify";
import {
  Container,
  Paper,
  TextField,
  Button,
  Grid,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";

const LogIn = () => {
  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState(false);

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const dispatch = useDispatch();
  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      await onLogin(values);

      // After login, fetch the protected data
      const { data } = await fetchProtectedInfo();

      // Use the data to authenticate the user and set user's ID
      dispatch(authenticateUser({ userId: data.userId }));

      localStorage.setItem("isAuth", "true");

      // Show success notification
      toast.success("🦉 Logged in successfully!", {
        position: "top-right",
        autoClose: 5000, // Close after 5 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.log(error.response.data.errors[0].msg);
      setError(error.response.data.errors[0].msg);

      // Show error notification
      toast.error(`🦉 Error: ${error.response.data.errors[0].msg}`, {
        position: "top-right",
        autoClose: 5000, // Close after 5 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div className="login-container">
      <form className="login-form" onSubmit={(e) => onSubmit(e)}>
        <h2 className="brand-title">
          <Link to="/" className="brand-content">
            <Logo className="brand-logo" />
            <span className="brand-name">Preclinique</span>
          </Link>
        </h2>

        <div className="input-group" id="login">
          <label htmlFor="email">Email address</label>

          <TextField
            fullWidth
            label="Email Address"
            type="email"
            variant="filled"
            id="email"
            name="email"
            value={values.email}
            onChange={onChange}
            required
            margin="normal"
          />
        </div>

        <div className="input-group" id="login">
          <label htmlFor="password">Password</label>

          <TextField
            fullWidth
            label="Password"
            type="password"
            variant="filled"
            id="password"
            name="password"
            value={values.password}
            onChange={onChange}
            required
            margin="normal"
          />
        </div>

        {error && <p style={{ color: "red", margin: "5px 0" }}>{error}</p>}

        <button className="submit-button">Log In</button>
        <div className="extras">
          <p className="remember-me">
            <input className="checkbox" type="checkbox" /> Remember Me
          </p>
          <Link to="/forgot-password" className="forgot-password-link">
            Forgot password?
          </Link>
        </div>
        <div className="signup-prompt">
          Not a member?{" "}
          <Link to="/register" className="signup-link">
            Register here
          </Link>
        </div>
      </form>
    </div>
  );
};

export default LogIn;
