import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    getUserLongFormatMockSubmissions,
    markQuestionAsCorrect,
    markMockAsComplete,
    hasUserSubmittedMock,
} from "../../../../../api/auth";

export default function Result() {
    const [submissions, setSubmissions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedSubmission, setSelectedSubmission] = useState(null);
    const [buttonState, setButtonState] = useState({}); // Track button text
    const [boxColors, setBoxColors] = useState({}); // Track box colors
    const [showValidationModal, setShowValidationModal] = useState(false); // Modal visibility state
    const [mark, setMark] = useState(0); // State for numeric input
    const [markError, setMarkError] = useState(""); // State for mark input error
    const userId = useSelector((state) => state.auth.userId);
    const navigate = useNavigate();

    // Helper function to parse user_answer dynamically
    const parseUserAnswer = (userAnswer) => {
        try {
            // Attempt to parse the userAnswer as JSON
            const parsedAnswer = JSON.parse(userAnswer);
            // If parsing succeeds and the parsed object has an "answer" field, return it
            if (parsedAnswer && typeof parsedAnswer === "object" && "answer" in parsedAnswer) {
                return parsedAnswer.answer;
            }
        } catch (error) {
            // If parsing fails, return the original userAnswer
            console.error("Failed to parse user answer:", error);
        }
        // Fallback to the original userAnswer if it's not a valid JSON string
        return userAnswer;
    };

    useEffect(() => {
        const fetchSubmissions = async () => {
            try {
                setIsLoading(true);

                // Check if user has already submitted
                const hasSubmitted = await hasUserSubmittedMock(userId);
                console.log("Mock submission check:", hasSubmitted);

                if (hasSubmitted.result === true) {
                    // User has already submitted, take an action (e.g., navigate away)
                    navigate("/dashboard/mock-test/questions/final-results", {
                        replace: true,
                    });
                    //return; // Stop execution here
                }

                const data = await getUserLongFormatMockSubmissions(userId);
                console.log("API Response:", data);

                const submissionsArray = Array.isArray(data)
                    ? data
                    : Object.values(data);
                console.log("Stored Submissions (After Fix):", submissionsArray[1]);

                // Set the box color based on the is_correct field from submission
                const boxColorMap = submissionsArray[1].reduce((acc, submission) => {
                    // If is_correct is true, set green; if false, set red; if null or undefined, set default
                    acc[submission.question_id] =
                        submission.is_correct === true
                            ? "green"
                            : submission.is_correct === false
                                ? "red"
                                : "transparent"; // default
                    return acc;
                }, {});

                setSubmissions(submissionsArray[1]);
                setBoxColors(boxColorMap); // Update the boxColors state with the determined colors
            } catch (error) {
                console.error("Failed to fetch submissions:", error);
                setError("Failed to fetch submissions. Please try again later.");
            } finally {
                setIsLoading(false);
            }
        };
        fetchSubmissions();
    }, [userId]);

    const handleBoxClick = (submission) => {
        console.log("Clicked Submission:", submission);
        setSelectedSubmission(submission);
        document.body.style.overflow = "hidden";
    };

    const handleCloseModal = () => {
        setSelectedSubmission(null);
        document.body.style.overflow = "auto";
    };

    const handleCloseValidationModal = () => {
        setShowValidationModal(false); // Close the modal when the user clicks close
    };

    const handleButtonClick = async (type) => {
        if (type === "submit") {
            // Ensure the mark does not exceed max_marks
            if (mark > selectedSubmission.max_marks) {
                setMarkError(`Mark cannot exceed ${selectedSubmission.max_marks}.`);
                return; // Stop execution if the mark is invalid
            }

            setButtonState((prev) => ({ ...prev, [type]: "Submitted..." }));

            try {
                // Call the function to mark the question with the user's input
                await markQuestionAsCorrect(userId, selectedSubmission.question_id, mark);

                // Update the submissions state to reflect the new is_correct status
                setSubmissions((prevSubmissions) =>
                    prevSubmissions.map((submission) =>
                        submission.question_id === selectedSubmission.question_id
                            ? { ...submission, is_correct: mark > 0 } // Update is_correct based on the mark
                            : submission
                    )
                );

                // Change the box color based on the mark
                setBoxColors((prev) => ({
                    ...prev,
                    [selectedSubmission.question_id]: mark > 0 ? "green" : "red",
                }));

                // Reset button text after 2 seconds
                setTimeout(() => {
                    setButtonState((prev) => ({
                        ...prev,
                        [type]: "Submit",
                    }));
                }, 2000);
            } catch (error) {
                console.error("Failed to submit mark:", error);
                setMarkError("Failed to submit mark. Please try again.");
            }
        }
    };

    const handleViewResults = () => {
        // Check if all questions are marked (correct/incorrect)
        const allMarked = submissions.every(
            (submission) => submission.is_correct !== null
        );

        if (!allMarked) {
            setShowValidationModal(true); // Show validation modal if not all are marked
        } else {
            markMockAsComplete(userId);
            navigate("/dashboard/mock-test/questions/final-results", {
                replace: true,
            });
            console.log("View Results button clicked.");
        }
    };

    if (isLoading) {
        return (
            <div className="loading">
                <div className="spinner"></div>
                <p>Loading...</p>
            </div>
        );
    }

    if (error) {
        return <div className="error">{error}</div>;
    }

    return (
        <div className="result-container">
            <h1 className="header">Submissions</h1>
            <div className="submissions-grid">
                {submissions.length > 0 ? (
                    submissions.map((submission, index) => (
                        <div
                            key={index}
                            className="submission-box"
                            onClick={() => handleBoxClick(submission)}
                            style={{
                                backgroundColor: boxColors[submission.question_id] || "white",
                                transition: "background-color 0.3s ease",
                            }}
                        >
                            <h3>{submission.question_text}</h3>
                            <p>
                                <strong>Your Answer:</strong> {parseUserAnswer(submission.user_answer)}
                            </p>
                        </div>
                    ))
                ) : (
                    <p>No submissions found.</p>
                )}
            </div>

            {selectedSubmission && (
                <div className="modal-overlay" onClick={handleCloseModal}>
                    <div className="modal" onClick={(e) => e.stopPropagation()}>
                        <h2>Submission Details</h2>
                        <button className="close-button" onClick={handleCloseModal}>
                            &times;
                        </button>
                        <div className="modal-content">
                            <p>
                                <strong>Question Text:</strong>{" "}
                                {selectedSubmission.question_text}
                            </p>
                            <p>
                                <strong>Correct Answer:</strong>{" "}
                                {selectedSubmission.question_answer}
                            </p>
                            <p>
                                <strong>Your Answer:</strong>{" "}
                                {parseUserAnswer(selectedSubmission.user_answer)}
                            </p>
                            <p>
                                <strong>Max Marks</strong>{" "}
                                {selectedSubmission.max_marks}
                            </p>

                            {/* Render subquestion_data if it exists */}
                            {selectedSubmission.subquestion_data && (
                                <>
                                    {/* Log the subquestion_data to the console */}
                                    {console.log("Subquestion Data:", selectedSubmission.subquestion_data)}
                                    <div className="subquestion-data">
                                        <h3>Subquestions:</h3>
                                        {selectedSubmission.subquestion_data.map((subquestion, index) => (
                                            <div key={index} className="subquestion">
                                                <p>
                                                    {" "}
                                                    {subquestion.question_b_text || subquestion.question_c_text}
                                                </p>
                                                <p>
                                                    <strong>Your Answer:</strong> {subquestion.user_answer}
                                                </p>
                                                <p>
                                                    <strong>Correct Answer:</strong>{" "}
                                                    {subquestion.is_correct} {/* Directly render the is_correct value */}
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                </>
                            )}
                        </div>

                        <div className="button-group">
                            <button
                                className="correct-button"
                                onClick={() => handleButtonClick("submit")}
                            >
                                {buttonState.submit || "Submit"}
                            </button>
                            <div className="mark-input-container">
                                <input
                                    type="number"
                                    step="0.5"
                                    min="0"
                                    max={selectedSubmission ? selectedSubmission.max_marks : 10}
                                    value={mark}
                                    onChange={(e) => {
                                        const newMark = parseFloat(e.target.value);
                                        if (newMark <= selectedSubmission.max_marks) {
                                            setMark(newMark);
                                            setMarkError(""); // Clear error message
                                        } else {
                                            setMarkError(`Mark cannot exceed ${selectedSubmission.max_marks}.`);
                                        }
                                    }}
                                    className="mark-input"
                                    placeholder="Enter mark"
                                />
                                {markError && <p className="error-message">{markError}</p>}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <button className="view-results-button" onClick={handleViewResults}>
                View Results
            </button>

            {/* Validation Modal */}
            {showValidationModal && (
                <div className="modal-overlay" onClick={handleCloseValidationModal}>
                    <div className="modal" onClick={(e) => e.stopPropagation()}>
                        <h2>WARNING</h2>
                        <p>All questions must be marked before proceeding.</p>
                        <button
                            className="close-button"
                            onClick={handleCloseValidationModal}
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}

            <style jsx>{`
                .modal-overlay {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100vw;
                    height: 100vh;
                    background: rgba(0, 0, 0, 0.7);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 9999;
                }

                .modal {
                    background: white;
                    padding: 20px;
                    border-radius: 8px;
                    width: 75%;
                    max-width: 90%;
                    max-height: 80vh; /* Set a max height for the modal */
                    overflow-y: auto; /* Enable vertical scrolling */
                    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
                    position: relative;
                }

                .modal-content {
                    margin-top: 10px;
                }

                .close-button {
                    position: absolute;
                    top: 10px;
                    right: 15px;
                    font-size: 20px;
                    border: none;
                    background: none;
                    cursor: pointer;
                }

                .submission-box {
                    border: 1px solid #ccc;
                    padding: 15px;
                    margin: 10px;
                    cursor: pointer;
                    border-radius: 5px;
                    text-align: center;
                    transition: background-color 0.3s ease;
                }

                .button-group {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 20px;
                }

                .correct-button {
                    flex: 1;
                    padding: 10px;
                    font-size: 16px;
                    border: none;
                    cursor: pointer;
                    transition: background 0.3s ease;
                    border-radius: 5px;
                    margin: 0 5px;
                    background-color: green;
                    color: white;
                }

                .correct-button:hover {
                    background-color: darkgreen;
                }

                .mark-input-container {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                }

                .mark-input {
                    padding: 10px;
                    font-size: 16px;
                    border: 1px solid #ccc;
                    border-radius: 5px;
                    margin: 0 5px;
                    text-align: center;
                }

                .mark-input:focus {
                    outline: none;
                    border-color: #007bff;
                }

                .error-message {
                    color: red;
                    font-size: 12px;
                    margin-top: 5px;
                }

                .subquestion-data {
                    margin-top: 20px;
                    padding: 10px;
                    border-top: 1px solid #ccc;
                }

                .subquestion {
                    margin-bottom: 15px;
                    padding: 10px;
                    border: 1px solid #eee;
                    border-radius: 5px;
                    background-color: #f9f9f9;
                }

                .subquestion p {
                    margin: 5px 0;
                }
            `}</style>
        </div>
    );
}