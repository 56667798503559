import React, { useEffect, useState } from "react";
import { setCurrentSubcategory, setUserAnswer, setMaxMarks, addSubQuestionData, clearSubQuestionData, setTraceToZero } from "../../../../../redux/reducers/question_reducer";
import { fetchSubcategories } from "../../../../../redux/slices/subcategoriesListSlice";
import { useDispatch, useSelector } from "react-redux";
import { useFetchQuestionsForMock } from "../../../../../hooks/FetchQuestion";

import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import "./MockTestQuestions.css";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AdjustIcon from "@mui/icons-material/Adjust";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { grey } from "@mui/material/colors";

import { getQuestionImageLinkForMock } from "../../../../../api/auth";
import ScrollDown from "./ScrollDown"; // Adjust the path if needed

export default function MockTestQuestions({
  onChecked,
  isSubmitted,
  isCorrect,
  getCorrectOptionId,
}) {
  const dispatch = useDispatch();
  const [showCheckboxes, setShowCheckboxes] = useState(false);
  const [showAnswer, setShowAnswer] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [revealButtonVisible, setRevealButtonVisible] = useState(true);
  const [textboxValue, setTextboxValue] = useState(""); // New state for textbox value
  const userId = useSelector((state) => state.auth.userId);
  const [image, setImage] = useState(null);
  const university_name = useSelector((state) => state.auth.universityName);
  const course = useSelector((state) => state.auth.courseName);
  const [subcategories, setSubcategories] = useState();

  const selectedSubcategories = useSelector(
    (state) => state.selectedSubcategories
  );
  const selectedQuestionIds = useSelector(
    (state) => state.selectedQuestions.questions
  );

  const [checkedAnswers, setCheckedAnswers] = useState([]);
  const { trace } = useSelector((state) => state.questions);
  const result = useSelector((state) => state.result.result);
  const [{ isLoading, apiData, serverError, metadata }] =
    useFetchQuestionsForMock(course, university_name);

    const [subcategoryScores, setSubcategoryScores] = useState({});
    const currentSubcategory = useSelector((state) => state.questions.currentSubcategory);

  const questionObj = useSelector(
    (state) => state.questions.queue[state.questions.trace]
  );

  const [isClicked, setIsClicked] = useState(false);
  const [buttonText, setButtonText] = useState(
    "Confirm Answer Here Before Submitting"
  );

  const [childTextboxValues, setChildTextboxValues] = useState({});
    const [selectedChildOptions, setSelectedChildOptions] = useState({});
    const [showChildQuestions, setShowChildQuestions] = useState(false);
    const [currentChildIndex, setCurrentChildIndex] = useState(0);
    const [isSaveAllClicked, setIsSaveAllClicked] = useState(false);

    


    // Always call useEffect unconditionally
    useEffect(() => {
        // Only dispatch if the questionObj and its queue are valid
        if (questionObj && questionObj.queue && questionObj.queue.length > 0) {
            dispatch(setCurrentSubcategory(questionObj.queue[0]?.subcategory));
        }
    }, [questionObj, dispatch]);

  useEffect(() => {
    if (metadata) {
      console.log("Subcategories:", metadata?.subcategories);
      console.log("Type Counts:", metadata?.typeCounts);

      let initialScores = {};
      for (const subcategory of Object.keys(metadata?.subcategories)) {
        initialScores[subcategory] = 0; // Starting score for each subcategory
      }

      setSubcategoryScores(initialScores);
      dispatch(fetchSubcategories(metadata));
    }
  }, [metadata, dispatch]);


    useEffect(() => {
        const fetchImage = async () => {
            if (questionObj && questionObj.id) {
                try {
                    const imageLink = await getQuestionImageLinkForMock(questionObj.id);
                    console.log("Fetched Image Link:", imageLink); // Log the fetched image link

                    // Check if the image link and its nested properties exist
                    if (imageLink?.data?.session?.[0]?.image) {
                        setImage(imageLink.data.session[0].image); // Set the image if the link exists
                    } else {
                        setImage(null); // Set image to null if no link is present
                        console.log("No image link found for this question.");
                    }
                } catch (error) {
                    console.error("Error fetching image link:", error);
                    setImage(null); // Set image to null in case of an error
                }
            } else {
                setImage(null); // Set image to null if questionObj or questionObj.id is undefined
            }
        };

        fetchImage();
    }, [questionObj]); // Trigger only when questionObj changes
    

    useEffect(() => {
        setTextboxValue("");
        setChildTextboxValues({});
        setShowChildQuestions(false);
        setCurrentChildIndex(0);

        // Store max_marks in Redux
        if (questionObj?.max_marks) {
            dispatch(setMaxMarks(questionObj.max_marks));
        }
    }, [questionObj, dispatch]);

  function onSelect(optionId, index) {
    onChecked(questionObj.id, optionId);
    let newCheckedAnswers = [...checkedAnswers];
    newCheckedAnswers[trace] = index;
    setCheckedAnswers(newCheckedAnswers);
  }

    const handleRevealButtonClick = () => {
        setShowCheckboxes(true);
        setShowAnswer(true);
        setRevealButtonVisible(false);
    };

    const handleChildTextboxSubmit = (index, correctAnswer) => {
        const userAnswer = childTextboxValues[index];
        const isCorrect = userAnswer?.trim().toLowerCase() === correctAnswer?.trim().toLowerCase();

        // Update the selected option based on correctness
        setSelectedChildOptions({
            ...selectedChildOptions,
            [index]: isCorrect ? "correct" : "incorrect",
        });

        console.log(`Child Question ${index + 1}:`, {
            userAnswer,
            correctAnswer,
            isCorrect,
        });
    };

    const handleChildOptionChange = (index, option) => {
        setSelectedChildOptions({
            ...selectedChildOptions,
            [index]: option,
        });
    };

  const handleOptionChange = (e) => {
    const selectedValue = e.target.value;
    let selectedOptionId;
    if (selectedValue === "correct") {
      const correctOption = questionObj.options.find(
        (option) => option.is_correct
      );
      selectedOptionId = correctOption ? correctOption.option_id : null;
    } else if (selectedValue === "incorrect") {
      selectedOptionId = "1";
    }
    setSelectedOption(selectedValue); // Update selectedOption state
    onChecked(questionObj.id, selectedOptionId);
  };

    useEffect(() => {
        setRevealButtonVisible(true);
        setShowCheckboxes(false);
        setShowAnswer(false);
        setTextboxValue(""); // Reset textbox value to its placeholder
        setSelectedOption(""); // Reset selectedOption to empty string to uncheck radio buttons
    }, [trace]); // Triggered when `trace` changes

const handleChildQuestionSubmit = (childQuestion, userAnswer) => {
    const subQuestionData = {
        question_b_text: childQuestion.question_b_text || childQuestion.question_c_text || childQuestion.question_d_text || childQuestion.question_e_text || childQuestion.question_f_text || childQuestion.question_g_text || childQuestion.question_h_text || childQuestion.question_i_text,
        user_answer: userAnswer,
        is_correct: childQuestion.correct_answer, // Add the correct answer as 'is_correct'
    };

    console.log("Submitting child question data:", subQuestionData);
    dispatch(addSubQuestionData(subQuestionData)); // Dispatch the structured data to Redux
};

  function renderControl(i, option) {
    if (isSubmitted[trace]) {
      if (checkedAnswers[trace] === i) {
        return isCorrect[trace] ? (
          <div
            style={{
              padding: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AdjustIcon color="primary" />
          </div>
        ) : (
          <div
            style={{
              padding: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AdjustIcon color="primary" />
          </div>
        );
      }
      if (getCorrectOptionId(questionObj.id) === option.option_id) {
        return (
          <div
            style={{
              padding: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <RadioButtonUncheckedIcon sx={{ color: grey[500] }} />
          </div>
        );
      }
    }
    return (
      <>
        <Radio
          checked={checkedAnswers[trace] === i}
          onChange={() => onSelect(option.option_id, i)}
          name="options"
          color="primary"
          disabled={isSubmitted[trace]}
        />
      </>
    );
  }

  function getOptionClass(i, option) {
    if (isSubmitted[trace] && checkedAnswers[trace] === i) {
      return "user-choice"; // Always apply a neutral style to the user's choice
    }
    return ""; // Default case
  }

  if (isLoading) return <h3 className="text-light">Loading...</h3>;
  if (serverError)
    return <h3 className="text-light">{serverError || "Unknown Error"}</h3>;

    if (!questionObj) {
        return <div>Loading...</div>;
    }

  const handleTextboxSubmit = () => {
    dispatch(setUserAnswer(textboxValue)); // Dispatch the user's answer to Redux
    setButtonText("Answer Saved... Please Proceed To Submit");
    setTimeout(() => {
      setButtonText("Confirm Answer Here Before Submitting");
    }, 3000); // Change the text back after 3 seconds
  };


   return (
    <div className="questions">
        {questionObj.type === 1 && (
            <>
                <h2 className="question-text">{questionObj?.question}</h2>

                {/* Add Warning Message for Multiple Choice Questions */}
                <div className="warning-message">
                  ⚠️ Only submit if you're 100% sure, as you can't change your answers
                  for multiple-choice questions!
                </div>

                {/* ✅ Add the ScrollDown animation ONLY for Type 1 questions */}
                <ScrollDown />

                <div>
                    {image ? (
                        <img src={image} alt="" style={{ maxWidth: "100%", height: "auto" }} />
                    ) : null}
                </div>
                <FormGroup role="radiogroup">
                    {questionObj?.options
                        .filter((option) => option.option_id !== undefined)
                        .map((option, i) => (
                            <div
                                key={option.option_id}
                                className={`option-border-style ${getOptionClass(i, option)}`}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginBottom: "8px",
                                        backgroundColor:
                                            checkedAnswers[trace] === i ? "#d3d3d3" : "transparent",
                                        borderRadius: "4px",
                                        padding: "8px",
                                    }}
                                >
                                    <FormControlLabel
                                        value={option.option_id}
                                        control={renderControl(i, option)}
                                        label={option.option_text}
                                        style={{
                                            width: "100%",
                                            marginRight: "0px",
                                            marginLeft: "0px",
                                            paddingRight: "72px",
                                            boxSizing: "border-box",
                                            paddingTop: "2px",
                                            cursor: "pointer",
                                        }}
                                    />
                                </div>
                                <Collapse in={isSubmitted[trace]} timeout="auto" unmountOnExit>
                                    <div className="explanation-style">
                                        {option?.explanation_text}
                                    </div>
                                </Collapse>
                            </div>
                        ))}
                </FormGroup>
            </>
        )}

            {/* Type 2 Questions */}
            {questionObj.type === 2 && (
                <div>
                    <h2 className="question-text">{questionObj?.question}</h2>
                    <div>
                        {image ? (
                            <img src={image} alt=" " style={{ maxWidth: "100%", height: "auto" }} />
                        ) : null}
                    </div>
                    <div>
                        <input
                            type="text"
                            placeholder="Enter your answer"
                            className="fancy-text-input"
                            value={textboxValue}
                            onChange={(e) => setTextboxValue(e.target.value)}
                        />
                        {revealButtonVisible && (
                            <button className="reveal-button" onClick={handleTextboxSubmit}>
                                {buttonText}
                            </button>
                        )}
                        {showCheckboxes && (
                            <div className="checkbox-container">
                                <div>
                                    <input
                                        type="radio"
                                        id="correct"
                                        name="answer"
                                        value="correct"
                                        checked={selectedOption === "correct"}
                                        onChange={handleOptionChange}
                                    />
                                    <label>Correct</label>
                                </div>
                                <div>
                                    <input
                                        type="radio"
                                        id="incorrect"
                                        name="answer"
                                        value="incorrect"
                                        checked={selectedOption === "incorrect"}
                                        onChange={handleOptionChange}
                                    />
                                    <label>Incorrect</label>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}

            {/* Type 3 Questions */}
            {questionObj.type === 3 && (
                <div>
                    {/* Render the parent question */}
                    <h2 className="question-text">{questionObj?.question}</h2>
                    <div>
                        {image ? (
                            <img src={image} alt=" " style={{ maxWidth: "100%", height: "auto" }} />
                        ) : null}
                    </div>

                    {/* Parent question textbox (always visible) */}
                    <div>
                        <input
                            type="text"
                            placeholder="Enter your answer"
                            className="fancy-text-input"
                            value={textboxValue}
                            onChange={(e) => setTextboxValue(e.target.value)}
                        />
                    </div>

                    {/* Parent confirm button (only visible before child questions are shown) */}
                    {!showChildQuestions && (
                        <button
                            className="reveal-button"
                            onClick={() => {
                                dispatch(setUserAnswer(textboxValue)); // Save the parent answer to `userAnswer`
                                setShowChildQuestions(true); // Show child questions after confirming the parent answer
                                setButtonText('Answer Saved... Please Proceed To Next Part');
                                setTimeout(() => {
                                    setButtonText('Confirm Answer Here Before Submitting');
                                }, 3000); // Change the text back after 3 seconds
                            }}
                        >
                            {buttonText}
                        </button>
                    )}

                    {/* Render child questions incrementally */}
                    {showChildQuestions && questionObj.child_question_ids && (
                        <div className="child-questions-container">
                            {/* Show all child questions up to the current index */}
                            {questionObj.child_question_ids
                                .slice(0, currentChildIndex + 1) // Only show up to the current index
                                .map((childQuestion, index) => (
                                    <div key={index} className="child-question">
                                        <h3 className="child-question-text">
                                            {childQuestion.question_b_text || childQuestion.question_c_text || childQuestion.question_d_text || childQuestion.question_e_text || childQuestion.question_f_text || childQuestion.question_g_text || childQuestion.question_h_text || childQuestion.question_i_text}
                                        </h3>
                                        <div>
                                            {childQuestion.image && (
                                                <img
                                                    src={childQuestion.image}
                                                    alt=""
                                                    style={{ maxWidth: "100%", height: "auto" }}
                                                />
                                            )}
                                        </div>

                                        {/* Textbox for the current child question */}
                                        <input
                                            type="text"
                                            placeholder="Enter your answer"
                                            className="fancy-text-input"
                                            value={childTextboxValues[index] || ""}
                                            onChange={(e) =>
                                                setChildTextboxValues({
                                                    ...childTextboxValues,
                                                    [index]: e.target.value,
                                                })
                                            }
                                        />

                                        {/* Render the "Confirm" button only for the most recent child question */}
                                        {index === currentChildIndex && (
                                            <button
                                                className="reveal-button"
                                                onClick={() => {
                                                    // Clear the existing subQuestionsData in Redux
                                                    dispatch(clearSubQuestionData());

                                                    // Loop through all visible child questions and add their answers to Redux
                                                    questionObj.child_question_ids
                                                        .slice(0, currentChildIndex + 1) // Only include visible child questions
                                                        .forEach((childQuestion, idx) => {
                                                            const userAnswer = childTextboxValues[idx]; // Get the user's answer from state
                                                            if (userAnswer) { // Only add if the user has provided an answer
                                                                const subQuestionData = {
                                                                    question_b_text: childQuestion.question_b_text || childQuestion.question_c_text || childQuestion.question_d_text || childQuestion.question_e_text || childQuestion.question_f_text || childQuestion.question_g_text || childQuestion.question_h_text || childQuestion.question_i_text,
                                                                    user_answer: userAnswer,
                                                                    is_correct: childQuestion.correct_answer, // Include the correct answer
                                                                };
                                                                dispatch(addSubQuestionData(subQuestionData)); // Add to Redux
                                                            }
                                                        });

                                                    if (currentChildIndex < questionObj.child_question_ids.length - 1) {
                                                        // If not the last child, move to the next child
                                                        setCurrentChildIndex(currentChildIndex + 1);
                                                    } else {
                                                        // If it's the last child, temporarily change the button text
                                                        const button = document.querySelector(`.child-question:nth-child(${index + 1}) .reveal-button`);
                                                        if (button) {
                                                            button.textContent = 'Answers Saved... Proceed to Submit';
                                                            setTimeout(() => {
                                                                button.textContent = 'Save All';
                                                            }, 2000);
                                                        }
                                                    }
                                                }}
                                            >
                                                {currentChildIndex < questionObj.child_question_ids.length - 1
                                                    ? "Confirm Answer Here Before Submitting"
                                                    : "Save All"}
                                            </button>
                                        )}
                                    </div>
                                ))}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}