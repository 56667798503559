import { createSlice } from "@reduxjs/toolkit";

const userAuthFromLocalStorage = () => {
  const isAuth = localStorage.getItem("isAuth");

  if (isAuth && JSON.parse(isAuth) === true) {
    return true;
  }

  return false;
};

const initialState = {
  isAuth: userAuthFromLocalStorage(),
  userId: null,
  role: null,
  courseName: null,
  universityName: null,
  classYear: null,
  customerID: null,
  avatar: "/avatars/default.png", // Default avatar
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authenticateUser: (state, action) => {
      state.isAuth = true;
      state.userId = action.payload.userId;
      state.role = action.payload.role;
      state.courseName = action.payload.courseName;
      state.universityName = action.payload.universityName;
      state.classYear = action.payload.classYear;
      state.customerID = action.payload.customerID;
      state.avatar = action.payload.avatar || "/avatars/avatar1.svg"; // Load avatar from backend
    },
    unauthenticateUser: (state) => {
      state.isAuth = false;
      state.userId = null;
      state.role = null;
      state.courseName = null;
      state.universityName = null;
      state.classYear = null;
      state.customerID = null;
      state.avatar = "/avatars/avatar1.svg";
    },
    updateAvatar: (state, action) => {
      state.avatar = action.payload; // Update avatar in Redux state
    },
  },
});

export const { authenticateUser, unauthenticateUser, updateAvatar } =
  authSlice.actions;

export default authSlice.reducer;
