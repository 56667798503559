// QuestionInterface.js
import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
//import CommentsSection from "./CommentsSection"; // Adjust the path as necessary
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import MockTestQuestions from "./MockTestQuestions";
import TextField from "@mui/material/TextField"; // Import TextField
import "react-simple-keyboard/build/css/index.css";
import Keyboard from "react-simple-keyboard";
import CalculateIcon from "@mui/icons-material/Calculate";

import CountdownTimer from "./CountdownTimer";
//import { setSubcategories } from '../../../../../redux/slices/subcategoriesListSlice';

import {
  MoveNextQuestion,
  MovePrevQuestion,
} from "../../../../../hooks/FetchQuestion";
import { PushAnswer } from "../../../../../hooks/setResult";

import { setTraceAction, clearSubQuestionData, clearLongQuestion } from "../../../../../redux/reducers/question_reducer";

import "./MockTestQuestionInterface.css"; //css

import IconButton from "@mui/material/IconButton";
import FlagIcon from "@mui/icons-material/Flag";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

/** redux store import */
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import MockTestReviewCard from "./MockTestReviewCard";
import {
  completeQuestion,
  flagQuestion,
  insertForReview,
  unflagQuestion,
  saveSession,
  reportQuestionInsert,
  submitMockQuestionData,
  submitMockQuestionResults,
  submitLongFormatQuestionInMockForReview,
} from "../../../../../api/auth";

const MockTestQuestionInterface = () => {
  const [questionObj, setQuestionObj] = useState(null); // New state for the current question
  const [flags, setFlags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const userId = useSelector((state) => state.auth.userId);
  const [isFlagged, setIsFlagged] = useState(false); // State to track if flagged
  const [checkedAnswers, setCheckedAnswers] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState([]);
  const [isCorrect, setIsCorrect] = useState([]);
  const [reviewCardOpen, setReviewCardOpen] = useState(false);
  //const [openDialog, setOpenDialog] = useState(false); // Existing dialog for finish button
  const [openReportDialog, setOpenReportDialog] = useState(false); // Ne
  const [reportText, setReportText] = useState(""); // State to store the report text
  const date = new Date();
  const university = useSelector((state) => state.auth.universityName);
  const course = useSelector((state) => state.auth.courseName);
  const [subcategories, setSubcategories] = useState();
  // Calculator State
  const [isCalculatorOpen, setIsCalculatorOpen] = useState(false);
  const [calculatorInput, setCalculatorInput] = useState("");

  // ✅ Timer State (1 hour 30 minutes = 5400 seconds)
  const [timeLeft, setTimeLeft] = useState(5400); // 90 minutes

  // ✅ Timer Effect
  useEffect(() => {
    if (timeLeft <= 0) {
      return; // ✅ Do nothing when timer reaches 0
    }

    const timer = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]); // ✅ Only depends on `timeLeft`

  // ✅ Format time (HH:MM:SS)
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(Math.floor(minutes / 60)).padStart(2, "0")}:${String(
      minutes % 60
    ).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
  };

  const { queue, trace } = useSelector((state) => state.questions);
  //const questionType = 3;

  //try dispatch all questiondata her

  const questionType = useSelector(
    (state) => state.questions.queue[state.questions.trace]?.type || null
  ); // question type for vsaq
  const correctAnswerForLongFormat = useSelector(
    (state) => state.questions.queue[trace]?.options[0].option_text
  );

  const handleQuestionClick = (questionNumber) => {
    dispatch(setTraceAction(questionNumber - 1)); // dispatch action to update the trace value
  };

  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenReportDialog = () => setOpenReportDialog(true);
  const handleCloseReportDialog = () => setOpenReportDialog(false);
  const handleReportTextChange = (event) => setReportText(event.target.value); // Update text state

  //const selectCurrentSubcategory = (state) => state.questions.currentSubcategory;
  const selectCurrentSubcategory = useSelector((state) => state.questions.currentSubcategory);

  const subcats = useSelector((state) => state.subcategoriesList.subcategories); // Get subcategories from Redux state
  const status = useSelector((state) => state.subcategoriesList.status); // Get the status from Redux state
  const [subcategoryScores, setSubcategoryScores] = useState({});
    const choice = useSelector((state) => state.questions.userAnswer);
    const subquestion_data = useSelector((state) => state.questions.subQuestionsData);
    const max_marks = useSelector((state) => state.questions.max_marks);


  // Function to toggle calculator
  const toggleCalculator = () => {
    setIsCalculatorOpen(!isCalculatorOpen);
  };

  // Function to update calculator input
  const handleCalculatorChange = (input) => {
    setCalculatorInput(input);
  };

  // Function to evaluate the calculator expression
  const evaluateExpression = () => {
    try {
      setCalculatorInput(eval(calculatorInput).toString()); // Simple eval-based calculator
    } catch (error) {
      setCalculatorInput("Error");
    }
  };

  const handleKeyPress = (button) => {
    if (button === "=") {
      try {
        setCalculatorInput(eval(calculatorInput).toString()); // Evaluate the expression
      } catch (error) {
        setCalculatorInput("Error");
      }
    } else if (button === "C") {
      setCalculatorInput(""); // Clear input
    } else {
      setCalculatorInput((prev) => prev + button); // Append input
    }
  };

  useEffect(() => {
    if (queue && queue.length > 0) {
      setFlags(Array(queue.length).fill(false));
    }
  }, [queue]);

    useEffect(() => {
        console.log("Current Subcategory:", selectCurrentSubcategory);
    }, [selectCurrentSubcategory,]);

  useEffect(() => {
    // Check if the status is 'succeeded' and subcategories are available
    if (status === "succeeded" && subcats) {
      // Initialize subcategory scores with correctScore and incorrectScore
      const initialScores = {};
      Object.keys(subcats).forEach((subcategory) => {
        initialScores[subcategory] = {
          correctScore: 0, // Start correctScore at 0
          incorrectScore: 0, // Start incorrectScore at 0
        };
      });

      // Update the state with the initial scores
      setSubcategoryScores(initialScores);

      // Log the initialized scores
      console.log("Subcategory scores initialized:", initialScores);
    } else if (status === "loading") {
      console.log("Loading subcategories...");
    } else if (status === "failed") {
      console.log("Failed to load subcategories");
    }
  }, [subcats, status]); // Depend on subcats and status

  const flaggedQuestions = flags.reduce((acc, flagged, index) => {
    if (flagged) acc.push(index + 1);
    return acc;
  }, []);

  const handleFinishClick = () => {
    console.log("Finish clicked, flagged questions:", flaggedQuestions);
    if (flaggedQuestions.length > 0) {
      setOpenDialog(true); // Open dialog if there are flagged questions
    } else {
      handleResults(); // Otherwise proceed to results
    }
  };

  const handleSubmitReport = () => {
    reportQuestionInsert(
      questionID, //questionId,
      reportText, //problem,
      university,
      course,
      // category,
      // subcategory,
      date
    );
    console.log("Report submitted:", reportText);
    // Placeholder for actual report submission logic
    setReportText(""); // Clear text after submission
    handleCloseReportDialog();
  };

  const handleCloseDialog = (shouldFinish) => {
    setOpenDialog(false);
    if (shouldFinish) {
      setIsLoading(true);
      setTimeout(() => {
        handleResults();
        setIsLoading(false);
      }, 2000);
    }
  };

  const didIComeFromResume = useSelector(
    (state) => state.questions.gettingResultFromResume
  );

  const answers = useSelector((state) => state.questions.answers);
  const questionIds = useSelector((state) => state.questions.queue); //question queue for prog saving
  const sessionarray = questionIds.map((question) => question.id);

  const questionnumber = useSelector((state) => state.questions.trace); //saves pos in queue for saving
  const result = useSelector((state) => state.result.result);
  const type23questiondata = [];

  const currentSubcategory = useSelector(
    (state) => state.questions.currentSubcategory
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const questionID = useSelector(
    (state) => state.questions.queue?.[trace]?.id || null
  );
  const questionText = useSelector((state) =>
    state.questions.queue
      ? state.questions.queue[trace]?.question || null
      : null
  );
  // const questionID = useSelector((state) => state.questions.queue[questionnumber].id);

  useEffect(() => {
    if (queue && queue.length > 0) {
      setFlags(Array(queue.length).fill(false));
    }
  }, [queue]); // This ensures flags are re-initialized whenever the queue changes.

  useEffect(() => {
    setIsFlagged(false); // Reset the flag color whenever `trace` changes
  }, [trace]);

  useEffect(() => {
    if (queue && queue.length > 0) {
      setQuestionObj(queue[trace]);
    }
  }, [trace, queue]);

  async function handleFlagClick() {
    let newFlags = [...flags];
    const index = trace; // assuming `trace` is the index of the current question

    try {
      if (newFlags[index]) {
        // Attempt to unflag the question
        const response = await unflagQuestion(userId, queue[index].id);
        if (response.data.success) {
          console.log("Question unflagged successfully", questionType);
          newFlags[index] = false;
        } else {
          console.error("Error unflagging question:", response.data.message);
        }
      } else {
        // Attempt to flag the question
        const response = await flagQuestion(userId, queue[index].id);
        if (response.data.success) {
          console.log("Question flagged successfully", questionType);
          newFlags[index] = true;
        } else {
          console.error("Error flagging question:", response.data.message);
        }
      }
      setFlags(newFlags);
    } catch (error) {
      console.error("Error in flag/unflag operation:", error);
      alert(
        "There was an error processing your flagging request. Please try again later."
      );
    }
  }

  /** next button event handler */
  function onNext() {
    if (trace < queue.length) {
      /** update the trace value by one using MoveNextAction*/
        dispatch(MoveNextQuestion());
        dispatch(clearSubQuestionData());
        dispatch(clearLongQuestion());
      
    }
  }

  function onPrev() {
    if (trace > 0) {
        dispatch(MovePrevQuestion());
        dispatch(clearSubQuestionData());
        dispatch(clearLongQuestion());
    }
  }

  function onChecked(questionId, optionId) {
    let updatedAnswers = [...checkedAnswers];
    updatedAnswers[trace] = { question_id: questionId, option_id: optionId };
    setCheckedAnswers(updatedAnswers);
  }

  function handleResults() {
    setIsLoading(true);
    //scores
    const subcategoryScoresJSON = JSON.stringify(subcategoryScores);

    console.log("console log for json", subcategoryScoresJSON);
    submitMockQuestionResults(userId, subcategoryScoresJSON);

    // Introduce a delay
    setTimeout(() => {
      if (queue.length) {
        navigate("/dashboard/mock-test/questions/results", {
          replace: true,
        });
      } else {
        alert("Please wait for the questions to load before proceeding.");
      }
      setIsLoading(false);
    }, 2000); // Delay in milliseconds, adjust as needed
  }

  function isAnswerCorrect(userAnswer) {
    // Fetch the corresponding correct answer object from the answers array
    const correctAnswer = answers.find(
      (a) => a.question_id === userAnswer.question_id
    );

    if (!correctAnswer) {
      console.error(
        `Correct answer for question ID ${userAnswer.question_id} not found in answers.`
      );
      return false;
    }

    // Return true if the user's selected option (option_id from result) matches the correct_option_id from the answers array
    return userAnswer.option_id === correctAnswer.correct_option_id;
  }

  const handleSaveSession = async () => {
    try {
      const saveSessionResult = await saveSession({
        user_id: userId,
        sessionarray: sessionarray, // Use questionIds instead of sessionarray
        questionnumber: questionnumber,
      });

      if (saveSessionResult.data && saveSessionResult.data.success) {
        console.log("Session saved successfully");
      } else {
        console.log("Error saving session:", saveSessionResult.data.message);
      }
    } catch (error) {
      console.error("Error saving session:", error.message);
    }
  };

  async function handleSubmit() {
    console.log("questionID", questionID);
    console.log("question Text", questionText);

    if (questionType === 1) {
      const currentAnswer = checkedAnswers[trace];

      if (!currentAnswer) {
        console.error("Current answer not found in checkedAnswers.");
        return;
      }

      // Initialize scores only if they haven't been initialized yet

      dispatch(PushAnswer(currentAnswer));

      const correct = isAnswerCorrect(currentAnswer);
      console.log("User's Answer:", currentAnswer.option_id);

      // Mock is correct + subcategory score
      if (correct) {
        if (subcategoryScores.hasOwnProperty(selectCurrentSubcategory)) {
          // Increase correctScore for the current subcategory
          subcategoryScores[selectCurrentSubcategory].correctScore += 1;
          console.log(
            "Subcategory scores after correct answer:",
            subcategoryScores
          );
        } else {
          console.log(
            `Subcategory '${selectCurrentSubcategory}' not found in subcategoryScores.`
          );
        }
      } else {
        if (subcategoryScores.hasOwnProperty(selectCurrentSubcategory)) {
          // Increase incorrectScore for the current subcategory
          subcategoryScores[selectCurrentSubcategory].incorrectScore += 1;
          console.log(
            "Subcategory scores after incorrect answer:",
            subcategoryScores
          );
        } else {
          console.log(
            `Subcategory '${selectCurrentSubcategory}' not found in subcategoryScores.`
          );
        }
      }

      const correctAnswerForLogging = answers.find(
        (a) => a.question_id === currentAnswer.question_id
      );

      let newIsSubmitted = [...isSubmitted];
      newIsSubmitted[trace] = true;
      setIsSubmitted(newIsSubmitted);

      let newIsCorrect = [...isCorrect];
      newIsCorrect[trace] = correct;
      setIsCorrect(newIsCorrect);

      try {
        const question = queue.find((q) => q.id === currentAnswer.question_id);
        const userOption = question.options.find(
          (opt) => opt.option_id === currentAnswer.option_id
        );
        const correctOption = question.options.find((opt) => opt.is_correct);

        //await completeQuestion(userId, question.id, true, correct);

        //console.log("questionID...",questionID);
        //console.log("userOption...", userOption);
        //console.log("correct...", correct);
        //console.log("date...", date);
        submitMockQuestionData(questionID, userOption.option_id, correct, date); //LOOK AT THIS TEST VIGOUROUSLY

        /*await insertForReview(
                    userId,
                    question.id,
                    question.question,
                    userOption?.option_text, // User selected option text
                    correctOption?.option_text, // Correct option text
                    correct
                );*/
      } catch (error) {
        console.error("Error in handleSubmit:", error);
      }
    }
    if ((questionType === 2) | (questionType === 3)) {
      //console.log("Ive submitted a question type 2 or 3 hahahah");
      //console.log("correct answer for type 23 is...", correctAnswerForLongFormat);
      // const correctOption =
      const newQuestion = {
        questionid: questionID,
        question_text: questionText,
        type: questionType,
        choiceText: choice, //get from mocktestquestions
      };
      const exists = type23questiondata.some(
        (q) =>
          q.questionid === newQuestion.questionid &&
          q.choiceText === newQuestion.choiceText
      );

      if (!exists) {
        type23questiondata.push(newQuestion);
        console.log("question list", type23questiondata);
        const submissionData = {
          question_id: questionID, // Assuming questionID is the same as question_id
          question_text: questionText,
          question_answer: correctAnswerForLongFormat, // You need to provide the correct answer for the question
          question_subcategory: currentSubcategory, // You need to provide the subcategory of the question
          user_answer: choice, // Assuming the user's answer is the choice
            user_id: userId, // You need to provide the user ID
            max_marks: max_marks,
          subquestion_data: subquestion_data,
          };
          console.log("submissionData", submissionData);
          console.log("subcategory choice", currentSubcategory);

        // Call the function to submit the question for review
        submitLongFormatQuestionInMockForReview(submissionData);
      } else {
        console.log(
          "Duplicate question with the same choice, not adding to the list."
        );
      }
    }
  }

  const handleSubmitAndSaveSession = () => {
    handleSubmit(); // Call handleSubmit function
    // handleSaveSession(); // Call handleSaveSession function
  };
  // Function to get the explanation for a given optionId from a question
  function getExplanation(questionId, optionId) {
    const question = queue.find((q) => q.id === questionId);
    return question?.options.find((opt) => opt.option_id === optionId)
      ?.explanation_text;
  }

  // Function to get the correct optionId for a given question
  function getCorrectOptionId(questionId) {
    const question = queue.find((q) => q.id === questionId);
    return question?.options.find((opt) => opt.is_correct)?.option_id;
  }

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        p: 2,
      }}
    >
      <Container
        height="100%"
        maxWidth="1600px"
        sx={{ mt: 4, mb: 4, height: "calc(100vh - 8rem)" }}
      >
        <Paper
          // Adjust the number to increase or decrease shadow depth
          square={false} // If set to true, it removes the border-radiu
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "80vh", // Setting minimum height
            height: "100%",
            position: "relative",
            overflow: "auto",
          }}
        >
          <div className="nav-button-container">
            <div className="inner-container">
              <div className="question-counter">
                Question {trace + 1} of {queue.length}
                {/* Flag IconButton added here */}
                <IconButton
                  className="flag-button"
                  onClick={handleFlagClick}
                  style={{ color: flags[trace] ? "red" : "inherit" }} // change color if flagged for current question
                >
                  <FlagIcon />
                </IconButton>
                <IconButton
                  className="calculator-button"
                  onClick={toggleCalculator}
                  sx={{
                    ml: 0.2,
                    backgroundColor: "#007BFF",
                    color: "white",
                    "&:hover": { backgroundColor: "#0056b3" },
                  }}
                >
                  <CalculateIcon sx={{ fontSize: "1.3rem" }} />{" "}
                  {/* Adjust size */}
                </IconButton>
                {/* ✅ Timer Display (Next to Calculator) */}
                <span
                  className="timer"
                  style={{
                    marginLeft: "20px",
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "red",
                  }}
                >
                  ⏳ {formatTime(timeLeft)}
                </span>
                {/* Calculator Modal */}
                <Dialog open={isCalculatorOpen} onClose={toggleCalculator}>
                  <DialogTitle>Calculator</DialogTitle>
                  <DialogContent>
                    <TextField
                      variant="outlined"
                      fullWidth
                      value={calculatorInput}
                      onChange={(e) => setCalculatorInput(e.target.value)}
                      sx={{ mb: 2 }}
                    />
                    <Keyboard
                      onKeyPress={(button) => handleKeyPress(button)} // Use a separate function
                      layout={{
                        default: [
                          "7 8 9 /",
                          "4 5 6 *",
                          "1 2 3 -",
                          "0 . = +",
                          "C",
                        ],
                      }}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={toggleCalculator} color="primary">
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>

              <Box className="mui-nav-box-root">
                {trace > 0 && (
                  <IconButton onClick={onPrev} aria-label="previous">
                    <ArrowBackIosIcon className="back-icon" />
                  </IconButton>
                )}
                {trace < queue.length - 1 ? (
                  <IconButton onClick={onNext} aria-label="next">
                    <ArrowForwardIosIcon />
                  </IconButton>
                ) : (
                  <button className="btn results" onClick={handleResults}>
                    Finish Test
                  </button>
                )}
              </Box>
            </div>
          </div>
          {/* Add  question content here - will add some sort of standalone component for the question ui */}

          <div className="parent-content-container">
            <div className="content-container">
              <MockTestQuestions
                onChecked={(qId, optId) => onChecked(qId, optId)}
                isSubmitted={isSubmitted}
                isCorrect={isCorrect}
                getCorrectOptionId={getCorrectOptionId}
              />
              {questionObj && (
                </*CommentsSection questionId={questionObj.id} userId={userId} */ test
                />
              )}
            </div>

            <div
              className="review-card-container"
              style={{ width: reviewCardOpen ? "300px" : "50px" }}
            >
              <MockTestReviewCard
                trace={trace}
                isSubmitted={isSubmitted}
                isCorrect={isCorrect}
                flags={flags} // Make sure to pass this prop
                onToggle={(isOpen) => setReviewCardOpen(isOpen)}
                onQuestionClick={handleQuestionClick}
              />
            </div>
          </div>

          <div className="submit-button-container">
            <div className="inner-container">
              <Box className="mui-box-root">
                {/* New Report Button */}

                <button
                  className="btn submit"
                  onClick={handleSubmitAndSaveSession}
                  disabled={isSubmitted[trace]}
                >
                  Submit
                </button>
                              {/*<button className="btn results" onClick={handleFinishClick}> */}
                              {/* {isLoading ? <CircularProgress size={24} /> : "Finish"} */}
                              {/* </button>*/}

                <Dialog
                  open={openReportDialog}
                  onClose={handleCloseReportDialog}
                >
                  <DialogTitle>Report an Issue</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Please describe the issue with this question.
                    </DialogContentText>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="report-issue"
                      label="Issue Description"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={reportText}
                      onChange={handleReportTextChange} // Capture user input
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseReportDialog} color="primary">
                      Cancel
                    </Button>
                    <Button onClick={handleSubmitReport} color="primary">
                      Submit
                    </Button>
                  </DialogActions>
                </Dialog>

                <Dialog open={openDialog} onClose={handleCloseDialog}>
                  <DialogTitle>{"Finish Session?"}</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      You have flagged questions: {flaggedQuestions.join(", ")}.
                      Do you want to review them or finish the session?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => handleCloseDialog(false)}>
                      Review
                    </Button>
                    <Button onClick={() => handleCloseDialog(true)} autoFocus>
                      Finish
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            </div>
          </div>
        </Paper>
      </Container>
    </Box>
  );
};

export default MockTestQuestionInterface;
